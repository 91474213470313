import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { getTalents } from '../model/talentsReducer';

export default function (state = Immutable.OrderedMap(), action = {}) {
    switch (action.type) {
        // case ActionTypes.LOGIN:
        case ActionTypes.RECEIVE_USER:
            return state.mergeDeep(Immutable.fromJS(action.user));

        case ActionTypes.CREATE_TALENT:
            return state.set('profileId', action.talent.id.toString());

        case ActionTypes.RECEIVE_FILTERED_JOB_LIST:
            return state.set('searchedJobIds', Immutable.fromJS(action.response.ids));

        case ActionTypes.RECEIVE_FAVORITE_JOB_LIST:
            return state.set('favoriteJobIds', Immutable.fromJS(action.ids));

        case ActionTypes.RECEIVE_APPLIED_JOB_LIST:
            return state.set('appliedJobIds', Immutable.fromJS(action.ids));

        case ActionTypes.ADD_FAVORITE_JOBS:
            let newFavoriteJobs = Immutable.List(action.ids);
            let oldFavoriteJobs = state.get('favoriteJobIds') || Immutable.List();
            newFavoriteJobs = oldFavoriteJobs.concat(newFavoriteJobs).toSet();
            return state.set('favoriteJobIds', newFavoriteJobs.toList());

        case ActionTypes.DELETE_FAVORITE_JOBS:
            let favoriteJobs = state.get('favoriteJobIds');
            let result = favoriteJobs.filter((jobId) => {
                return action.ids.indexOf(jobId) === -1;
            });
            return state.set('favoriteJobIds', result);

        case ActionTypes.RECEIVE_TALENT:
            return state.set('profileId', action.talent.id);

        case ActionTypes.RECEIVE_APPLICATION:
            return state.update('appliedJobIds', (ids = Immutable.List()) => {
                return action.jobId ? ids.push(action.jobId) : ids;
            });

        case ActionTypes.DELETE_APPLICATION:
            return state.update('appliedJobIds', (ids = Immutable.List()) => {
                let index = ids.findIndex(id => {
                    return id === action.application.jobId;
                });
                return index >= 0 ? ids.delete(index) : ids;
            });

        case ActionTypes.LOGOUT:
            return state.clear();

        default:
            return state;
    }
}

export const getUser = (state) => state.controller.user;

export const getFavoriteJobIds = (state) => state.controller.user.get('favoriteJobIds') || Immutable.List();

export const getAppliedJobIds = (state) => state.controller.user.get('appliedJobIds') || Immutable.List();

const getJobId = (state, jobId) => jobId;

export const getCurrentTalentId = (state) => state.controller.user.get('profileId') || 0;

export const getCurrentTalent = createSelector(
    [getCurrentTalentId, getTalents],
    (currentTalentId, talents) => talents.get(currentTalentId.toString()) || Immutable.Map()
);

export const getBasicInfo = createSelector(
    [getCurrentTalent],
    (talent) => talent.filter((value, key) => [
        'id',
        'firstName',
        'lastName',
        'fullName',
        'photoUrl',
        'email',
        'phone',
        'title',
        'company',
        'city',
        'province',
        'country',
    ].indexOf(key) >= 0)
);

export const getExperiences = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('experiences') || Immutable.List())
);

export const getEducations = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('educations') || Immutable.List())
);

export const getSkills = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('skills') || Immutable.List())
);

export const getCertificates = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('certificates') || Immutable.List())
);

export const getContacts = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('contacts') || Immutable.List())
);

export const getResumes = createSelector(
    [getCurrentTalent],
    (talent) => (talent.get('resumes') || Immutable.List())
);

export const getApplications = createSelector(
    [getCurrentTalent],
    (talent) => talent.get('applications') || Immutable.List()
);

export const getApplication = createSelector(
    [getApplications, getJobId],
    (applications, jobId) => {
        return applications.find(application => application.get('jobId') === jobId);
    }
);
