import React, {Component} from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {getIframeParams} from '../../../../reducers/controller/iframe';
import toJS from 'src/app/components/hocs/toJS';

const getSearchString = (params) => {
    let search = '';
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            search += `&${key}=${params[key]}`;
        }
    }
    if(search) search = '?' + search.slice(1);
    return search;
};

const getShareLink = (jobId, params = {}) => {
    const url = `${location.origin}/jobs/detail/${jobId}`;
    if(params.from) {
        const to = btoa(url);
        const paramsCopy = {...params};
        delete paramsCopy.from;
        paramsCopy.to = to;
        console.log('access from iframe', params, paramsCopy);
        return `${params.from}${getSearchString(paramsCopy)}`;
    }
    else {
        console.log('direct access', params);
        return `${url}${getSearchString(params)}`;
    }
};

class SharePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopied: false,
        };
        this.textField = React.createRef();
    }

    /* API */
    handleCopyToClipboard = () => {
        this.textField.current.select();
        document.execCommand('copy');
        this.setState({isCopied: true});
    };

    render() {
        const {jobId, params} = this.props;
        const shareLink = getShareLink(jobId, params);
        return (
            <>
                {this.state.isCopied && <Typography color="primary" gutterBottom>Link copied!</Typography>}
                <TextField
                    fullWidth multiline inputRef={this.textField}
                    value={shareLink}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    params: getIframeParams(state)
});

export default connect(mapStateToProps)(toJS(SharePanel));