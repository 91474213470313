import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

export default function (state = Immutable.OrderedMap(), action = {}) {
    switch (action.type) {

        case ActionTypes.CREATE_TALENT:
        case ActionTypes.RECEIVE_TALENT:
            return state.mergeDeepIn([action.talent.id.toString()], Immutable.fromJS(action.talent));
        case ActionTypes.RECEIVE_RESUMES:
            return state.setIn([action.talentId.toString(), 'resumes'], Immutable.fromJS(action.resumes));
        case ActionTypes.RECEIVE_RESUME_INFO:
            return state.updateIn([action.resume.talentId.toString(), 'resumes'],
                (resumes = Immutable.List()) => resumes.push(Immutable.fromJS(action.resume))
            );
        case ActionTypes.DELETE_RESUME_INFO:
            return state.updateIn([action.talentId.toString(), 'resumes'], (resumes = Immutable.List()) => {
                return resumes.filter(resume => resume.get('id') !== action.resumeId);
            });

        case ActionTypes.RECEIVE_APPLICATION:
            return state.updateIn([action.talentId.toString(), 'applications'], (applications = Immutable.List()) => {
                let index = applications.findIndex(application => {
                    return application.get('id') === action.application.id;
                });
                if (index < 0) {
                    return applications.push(Immutable.fromJS(action.application));
                } else {
                    return applications.set(index, Immutable.fromJS(action.application));
                }
            });
        case ActionTypes.RECEIVE_APPLIED_JOB_LIST:
            return state.setIn([String(action.talentId),'applications'],Immutable.fromJS(action.applications));

        case ActionTypes.DELETE_APPLICATION:
            return state.updateIn([action.talentId.toString(), 'applications'], (applications = Immutable.List()) => {
                let index = applications.findIndex(application => {
                    return application.get('id') === action.application.id;
                });
                if (index >= 0) {
                    return applications.delete(index);
                }
                return state;
            });

        case ActionTypes.ADD_TALENT_EXPERIENCE:
            return state.updateIn([action.talentId.toString(), 'experiences'], (experiences = Immutable.List()) =>
                experiences.push(Immutable.fromJS(action.experience))
            );
        case ActionTypes.UPDATE_TALENT_EXPERIENCE:
            return state.updateIn([action.talentId.toString(), 'experiences'], (experiences = Immutable.List()) => {
                let index = experiences.findIndex(experience => experience.get('id') === action.experience.id);
                return experiences.set(index, Immutable.fromJS(action.experience));
            });
        case ActionTypes.DELETE_TALENT_EXPERIENCE:
            return state.updateIn([action.talentId.toString(), 'experiences'], (experiences = Immutable.List()) => {
                return experiences.filterNot(experience => experience.get('id') === action.experienceId);
            });

        case ActionTypes.ADD_TALENT_EDUCATION:
            return state.updateIn([action.talentId.toString(), 'educations'], (educations = Immutable.List()) =>
                educations.push(Immutable.fromJS(action.education))
            );
        case ActionTypes.UPDATE_TALENT_EDUCATION:
            return state.updateIn([action.talentId.toString(), 'educations'], (educations = Immutable.List()) => {
                let index = educations.findIndex(education => education.get('id') === action.education.id);
                return educations.set(index, Immutable.fromJS(action.education));
            });
        case ActionTypes.DELETE_TALENT_EDUCATION:
            return state.updateIn([action.talentId.toString(), 'educations'], (educations = Immutable.List()) => {
                return educations.filterNot(education => education.get('id') === action.educationId);
            });

        case ActionTypes.ADD_TALENT_CERTIFICATE:
            return state.updateIn([action.talentId.toString(), 'certificates'], (certificates = Immutable.List()) =>
                certificates.push(Immutable.fromJS(action.certificate))
            );
        case ActionTypes.UPDATE_TALENT_CERTIFICATE:
            return state.updateIn([action.talentId.toString(), 'certificates'], (certificates = Immutable.List()) => {
                let index = certificates.findIndex(certificate => certificate.get('id') === action.certificate.id);
                return certificates.set(index, Immutable.fromJS(action.certificate));
            });
        case ActionTypes.DELETE_TALENT_CERTIFICATE:
            return state.updateIn([action.talentId.toString(), 'certificates'], (certificates = Immutable.List()) => {
                return certificates.filterNot(certificate => certificate.get('id') === action.certificateId);
            });

        case ActionTypes.ADD_TALENT_SKILL:
            return state.updateIn([action.talentId.toString(), 'skills'], (skills = Immutable.List()) => {
                return skills.push(Immutable.fromJS(action.skill));
            });
        case ActionTypes.DELETE_TALENT_SKILL:
            return state.updateIn([action.talentId.toString(), 'skills'], (skills = Immutable.List()) => {
                return skills.filterNot(skill => skill.get('id') === action.skillId);
            });
        case ActionTypes.CREATE_TALENT_CONTACT:
            return state.updateIn([action.talentId.toString(), 'contacts'], (contacts = Immutable.List()) => {
                return contacts.push(Immutable.fromJS(action.contact));
            });
        case ActionTypes.UPDATE_TALENT_CONTACT:
            return state.updateIn([action.talentId.toString(), 'contacts'], (contacts = Immutable.List()) => {
                let index = contacts.findIndex(contact => contact.get('id') === action.contact.id);
                return contacts.set(index, Immutable.fromJS(action.contact));
            });
        case ActionTypes.DELETE_TALENT_CONTACT:
            return state.updateIn([action.talentId.toString(), 'contacts'], (contacts = Immutable.List()) => {
                return contacts.filterNot(contact => contact.get('id') === action.contact.id);
            });

        case ActionTypes.LOGOUT:
            return state.clear();
        default:
            return state;
    }
}

const getTalentId = (state, talentId) => talentId;

export const getTalents = (state) => state.model.talents;

export const getTalent = createSelector(
    [getTalents, getTalentId],
    (talents, talentId) => talents.get(talentId) || Immutable.Map()
);
