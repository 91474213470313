import * as apnSDK from './../../apn-sdk/';
import * as ActionTypes from '../constants/actionTypes';
import { normalize, schema } from 'normalizr';
import { replace } from 'connected-react-router';
import { JobsPerPage } from '../constants/appConstants';
import { getIsFetching, getKeywords, getSearchedJobCount } from '../reducers/controller/searchReducer';
import { getCurrentTalentId } from "../reducers/controller/userReducer";

const job = new schema.Entity('jobs');
const application = new schema.Entity('applications', {
    job
});
const esJob = new schema.Entity('jobs');
//const esHit = new schema.Entity('esHits', {_source: esJob}, {idAttribute: '_id'});

export const getJobListES = () => (dispatch, getState) => {
    const state = getState();

    if (getIsFetching(state)) {
        return Promise.resolve('loading...')
    }

    dispatch({
        type: ActionTypes.REQUEST_JOB_LIST,
        tab: 'es',
    });
    const keywords = getKeywords(state).toJS();
    //keywords.jobType = 'contract';//'full_time';
    //const tenantId = 0;
    const query = getESQuery(keywords);
    const from = getSearchedJobCount(state);
    const sort = [{ 'postingDate': 'desc' }, '_score', { 'id': 'desc' }];
    query.bool.filter.push({ 'terms': { 'status': ['open', 'onhold'] } });

    return apnSDK.getJobListES(from, JobsPerPage, sort, query)
        .then(({ response, pagination }) => {
            console.log('[ES] getJobListES() response: ', response);
            /*const normalizedData = normalize(response.hits.hits, [esHit]);
            dispatch({
                type: ActionTypes.RECEIVE_JOB_LIST,
                response: {
                    ids: normalizedData.result.map(idStr => parseInt(idStr)),
                    jobs: normalizedData.entities.jobs,
                },
                total: response.hits.total
            });*/
            const normalizedData = normalize(response, [esJob]);
            dispatch({
                type: ActionTypes.RECEIVE_JOB_LIST,
                response: {
                    ids: normalizedData.result.map(idStr => parseInt(idStr)),
                    jobs: normalizedData.entities.jobs,
                },
                total: pagination.totalCount
            });
            // return headers
        })
        .catch(err => {
            dispatch({
                type: ActionTypes.FAILURE_JOB_LIST,
                tab: 'es',
            });
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: err.message || err,
                    type: 'error'
                }
            });
            throw err
        })
};

export const getJobDetail = (jobId) => (dispatch) => {
    dispatch({
        type: ActionTypes.REQUEST_JOB,
    });
    return apnSDK.getJobDetail(jobId)
        .then(({ response }) => {
            dispatch({
                type: ActionTypes.RECEIVE_JOB,
                job: response,
            });
            return response;
        })
        .catch(status => {
            if (status === 404) {
                dispatch(replace('/nomatch'));
            }
            throw status
        })
};

export const getFavoriteJobs = () => (dispatch) => {
    dispatch({
        type: ActionTypes.REQUEST_FAVORITE_JOB_LIST,
    });
    return apnSDK.getFavoriteJobs()
        .then(({ response }) => {
            const normalizedData = normalize(response, [job]);
            dispatch({
                type: ActionTypes.RECEIVE_FAVORITE_JOB_LIST,
                ids: normalizedData.result,
                jobs: normalizedData.entities.jobs,
            })
        })
        .catch(err => {
            console.log(err);
            // throw err
        })
};

export const getAppliedJobs = () => (dispatch, getState) => {
    dispatch({
        type: ActionTypes.REQUEST_APPLIED_JOB_LIST,
    });
    const talentId = getCurrentTalentId(getState());

    return apnSDK.getAppliedJobs(talentId)
        .then(({ response }) => {
            console.log(response);

            const normalizedData = normalize(response, [application]);
            dispatch({
                type: ActionTypes.RECEIVE_APPLIED_JOB_LIST,
                ids: Object.keys(normalizedData.entities.jobs || {}).map((id) => parseInt(id)),
                jobs: normalizedData.entities.jobs || {},
                applications: normalizedData.entities.applications,
                talentId
            })
        })
        .catch(err => {
            console.log(err);
            // throw err
        })
};

export const favoriteJob = (jobId) => (dispatch) => {
    // Optimistic update
    dispatch({
        type: ActionTypes.ADD_FAVORITE_JOBS,
        ids: [jobId]
    });
    return apnSDK.favoriteJob(jobId)
        .then(({ response }) => {
            return response;
        }).catch(err => {
            dispatch({
                type: ActionTypes.DELETE_FAVORITE_JOBS,
                ids: [jobId],
            });
        })
};

export const unfavoriteJob = (jobId) => (dispatch) => {
    // Optimistic update
    dispatch({
        type: ActionTypes.DELETE_FAVORITE_JOBS,
        ids: [jobId],
    });
    return apnSDK.unfavoriteJob(jobId)
        .catch(err => {
            dispatch({
                type: ActionTypes.ADD_FAVORITE_JOBS,
                ids: [jobId]
            });
        })
};


const getESQuery = (keywords) => {
    const query = {
        bool: {
            filter: [],
            must: []
        }
    };
    if (!keywords) return query;
    Object.keys(keywords).forEach(key => {
        if (keywords[key]) {
            switch (key) {
                case 'q':
                    if (isNaN(keywords[key])) {
                        query.bool.must.push({
                            multi_match: {
                                fields: ['title', 'company', 'keywords'],
                                query: keywords[key],
                                operator: 'or'
                            }
                        });
                    } else {
                        query.bool.must.push({
                            term: {
                                "id": keywords[key],
                            }
                        });
                    }
                    break;
                case 'location':
                    query.bool.must.push({
                        multi_match: {
                            fields: ['province', 'city', 'country'],
                            query: keywords[key],
                            operator: 'or'
                        }
                    });
                    break;
                case 'minSalary':
                    query.bool.filter.push({
                        range: {
                            'payRateFrom': { lte: keywords[key] },
                        }
                    });
                    break;
                case 'maxSalary':
                    query.bool.filter.push({
                        range: {
                            'payRateTo': { gte: keywords[key] },
                        }
                    });
                    break;
                case 'postingDateFrom':
                    query.bool.filter.push({
                        range: {
                            'postingDate': { gte: keywords[key] },
                        }
                    });
                    break;
                case 'postingDateTo':
                    query.bool.filter.push({
                        range: {
                            'postingDate': { lte: keywords[key] },
                        }
                    });
                    break;
                case 'jobType':
                    query.bool.filter.push({
                        term: {
                            'jobType': keywords[key],
                        }
                    });
                    break;
                case 'tenantId':
                    if (keywords[key] !== '0') {
                        query.bool.filter.push({
                            term: {
                                'tenantId': keywords[key]
                            }
                        });
                    }
                    break;
                default:
                    query.bool.must.push({
                        match: {
                            [key]: {
                                query: keywords[key],
                                // "fuzziness": "AUTO",
                                operator: 'or'
                            }
                        }
                    });
            }
        }
    });

    return query
};
