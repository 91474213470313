import * as ActionTypes from '../constants/actionTypes';
import * as apnSDK from '../../apn-sdk';
import {getCurrentTalentId} from '../reducers/controller/userReducer';
import {normalize, schema} from 'normalizr';

const job = new schema.Entity('jobs');
const application = new schema.Entity('applications', {
    job
});
const talentResume = new schema.Entity('talentResumes', {}, {
    processStrategy: (value) => {
        return {
            id: value.id,
            talentId: value.talentId,
            name: value.name,
            // boxLink: value.boxLink,
            s3Link: value.s3Link,
            sourceType: value.sourceType,
            text: value.text,

            createdBy: value.createdBy,
            createdDate: value.createdDate,
            lastModifiedDate: value.lastModifiedDate,
        };
    }
});

/* talent */
export const createTalent = (talent) => (dispatch, getState) => {
    return apnSDK.createTalent(talent)
        .then(({response}) => {
            dispatch({
                type: ActionTypes.CREATE_TALENT,
                talent: response,
            });
            return response;
        });
};

export const updateTalent = (talent) => (dispatch, getState) => {
    talent.id = getCurrentTalentId(getState());
    return apnSDK.updateTalent(talent)
        .then(({response}) => {
            const talent = response;
            talent.applications = talent.applications || [];
            const normalizedData = normalize(talent.applications, [application]);
            dispatch({
                type: ActionTypes.RECEIVE_TALENT,
                talent: response,
                appliedJobIds: Object.keys(normalizedData.entities.jobs || {}).map(idStr => parseInt(idStr)),
            });
            return response;
        });
};

export const getResumesByTalentId = () => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.getResumesByTalentId(talentId)
        .then(({ response }) => {
                const normalizedData = normalize(response, [talentResume]);
                const resumes = Object.values(normalizedData.entities['talentResumes']||{});
                dispatch({
                    type: ActionTypes.RECEIVE_RESUMES,
                    talentId,
                    resumes
                });
                return normalizedData
            }
        )
};

export const uploadAvatar = (file) => (dispatch, getState) => {
    return apnSDK.uploadAvatar(file)
        .then(({response}) => {
                console.log('upload file: ', response);
                // return message to component
                return response
            }
        )
};

/* education */
export const createEducation = (education) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    education['talentId'] = talentId;
    return apnSDK.createEducation(education)
        .then(({response}) => {
            console.log('createEducation', response);
            dispatch({
                type: ActionTypes.ADD_TALENT_EDUCATION,
                education: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const updateEducation = (education) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    education['talentId'] = talentId;
    return apnSDK.updateEducation(education)
        .then(({response}) => {
            console.log('updateEducation', response);
            dispatch({
                type: ActionTypes.UPDATE_TALENT_EDUCATION,
                education: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const deleteEducation = (educationId) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.deleteEducation(educationId)
        .then(() => {
            dispatch({
                type: ActionTypes.DELETE_TALENT_EDUCATION,
                educationId,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

/* experience */
export const createExperience = (experience) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    experience['talentId'] = talentId;
    return apnSDK.createExperience(experience)
        .then(({response}) => {
            console.log('createExperience', response);
            dispatch({
                type: ActionTypes.ADD_TALENT_EXPERIENCE,
                experience: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const updateExperience = (experience) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    experience['talentId'] = talentId;
    return apnSDK.updateExperience(experience)
        .then(({response}) => {
            console.log('updateExperience', response);
            dispatch({
                type: ActionTypes.UPDATE_TALENT_EXPERIENCE,
                experience: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const deleteExperience = (experienceId) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.deleteExperience(experienceId)
        .then(() => {
            dispatch({
                type: ActionTypes.DELETE_TALENT_EXPERIENCE,
                experienceId: experienceId,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

/* skill */
export const createSkill = (skill) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    skill['talentId'] = talentId;
    return apnSDK.createSkill(skill)
        .then(({response}) => {
            console.log(response);
            dispatch({
                type: ActionTypes.ADD_TALENT_SKILL,
                skill: response,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const updateSkill = (skill) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    skill['talentId'] = talentId;
    return apnSDK.updateSkill(skill)
        .then(({response}) => {
            console.log(response);
        })

};

export const deleteSkill = (skillId) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.deleteSkill(skillId)
        .then(() => {
            dispatch({
                type: ActionTypes.DELETE_TALENT_SKILL,
                skillId: skillId,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

/* certificate */
export const createCertificate = (certificate) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    certificate['talentId'] = talentId;
    return apnSDK.createCertificate(certificate)
        .then(({response}) => {
            console.log('createCertificate', response);
            dispatch({
                type: ActionTypes.ADD_TALENT_CERTIFICATE,
                certificate: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const updateCertificate = (certificate) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    certificate['talentId'] = talentId;
    return apnSDK.updateCertificate(certificate)
        .then(({response}) => {
            console.log('updateCertificate', response);
            dispatch({
                type: ActionTypes.UPDATE_TALENT_CERTIFICATE,
                certificate: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const deleteCertificate = (certificateId) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.deleteCertificate(certificateId)
        .then((response) => {
            dispatch({
                type: ActionTypes.DELETE_TALENT_CERTIFICATE,
                certificateId: certificateId,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

/* contact */
export const createContact = (contact) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    contact['talentId'] = talentId;
    return apnSDK.createContact(contact)
        .then(({response}) => {
            dispatch({
                type: ActionTypes.CREATE_TALENT_CONTACT,
                contact: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const updateContact = (contact) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    return apnSDK.updateContact(contact)
        .then(({response}) => {
            dispatch({
                type: ActionTypes.UPDATE_TALENT_CONTACT,
                contact: response,
                talentId
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

export const deleteContact = (contact) => (dispatch, getState) => {
    const talentId = getCurrentTalentId(getState());
    contact['talentId'] = talentId;
    return apnSDK.deleteContact(contact)
        .then(() => {
            dispatch({
                type: ActionTypes.DELETE_TALENT_CONTACT,
                contact: contact,
                talentId
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        });
};

/* resume */
export const uploadResume = (resumeFile) => (dispatch, getState) => {
    return apnSDK.parseResume(resumeFile)
        .then(({response: resume}) => {
            return dispatch(createResume(resume));
        })
        .catch(err => {
            console.log('error');
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: err.message || err,
                    type: 'error'
                }
            });
        });
};

export const createResume = (resume) => (dispatch, getState) => {
    resume['talentId'] = getCurrentTalentId(getState());
    return apnSDK.createResume(resume)
        .then(({response}) => {
            dispatch({
                type: ActionTypes.RECEIVE_RESUME_INFO,
                resume: response
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Can not create the resume',
                    type: 'error'
                }
            });
        });
};

export const deleteResume = (resumeId) => (dispatch, getState) => {
    return apnSDK.deleteResume(resumeId)
        .then(response => {
            dispatch({
                type: ActionTypes.DELETE_RESUME_INFO,
                resumeId: resumeId,
                talentId: getCurrentTalentId(getState())
            });
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Resume deleted',
                    type: 'success'
                }
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Can not delete the resume',
                    type: 'error'
                }
            });
        });
};
