import React, {Component} from 'react';

import Typography from '@material-ui/core/Typography';

import Layout from 'src/app/components/core/layouts/Layout';
import SearchFilterForm from 'src/app/components/forms/SearchFilterForm';

const getSearchDescription = (keywords) => {
    const {
        q = '',
        title = '',
        city = '',
        province = '',
        location = '',
    } = keywords;
    const keyword = q + (title && ` ${title}`) || 'All';
    const place = location || (city + (province && `, ${province}`));
    return `${keyword} Jobs` + (place && ` in ${place}`);
};

class SearchFilter extends Component {
    render() {
        const {keywords, result, onApplySearchFilter} = this.props;
        const description = getSearchDescription(keywords);
        return (
            <Layout padding>
                <Typography variant="h6" paragraph>
                    {description}
                    {result.total >= 0 && ` (${result.total} Results)`}
                </Typography>
                <SearchFilterForm keywords={keywords} onSubmit={onApplySearchFilter} />
            </Layout>
        );
    }
}

export default SearchFilter;
