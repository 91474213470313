import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DialogLayout from '../core/DialogLayout';
import CertificateForm from '../forms/profile/CertificateForm';

import toJS from '../hocs/toJS';
import {createCertificate, updateCertificate, deleteCertificate} from '../../actions/talentActions';
import {getCertificates} from '../../reducers/controller/userReducer';

class CertificatesCard extends Component {
    handleCreateCertificate = (certificate) => this.props.dispatch(createCertificate(certificate));

    handleUpdateCertificate = (certificate) => this.props.dispatch(updateCertificate(certificate));

    handleDeleteCertificate = (certificate) => this.props.dispatch(deleteCertificate(certificate.id));

    render() {
        const {certificates} = this.props;
        return (
            <Card>
                <CardHeader
                    title="Certificates"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Create Certificate"
                            actionButtonText="Submit"
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <CertificateForm
                                onSubmit={this.handleCreateCertificate}
                            />
                        </DialogLayout>
                    }
                />
                {certificates.map((certificate, index) => {
                    const startDate = certificate.startDate;
                    const endDate = certificate.endDate;
                    const start = startDate ? moment(startDate).format('L') + ' - ' : '';
                    const end = endDate ? moment(endDate).format('L') : 'Present';
                    const name = certificate.name || '';
                    const description = certificate.description;
                    return (
                        <div key={index}>
                            <Divider />
                            <div className="flex spaceBetween">
                                <CardContent>
                                    <Typography variant='body2'>{name}</Typography>
                                    <Typography variant='body1'>{description}</Typography>
                                    <Typography variant='caption'>{start + end}</Typography>
                                </CardContent>
                                <CardActions>
                                    <DialogLayout
                                        variant="form"
                                        title="Update Certificate"
                                        actionButtonText="Save"
                                    >
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                        <CertificateForm
                                            onSubmit={this.handleUpdateCertificate}
                                            certificate={certificate}
                                        />
                                    </DialogLayout>
                                    <DialogLayout
                                        onConfirm={() => this.handleDeleteCertificate(certificate)}
                                        actionButtonText="Delete"
                                        disableCloseOnAction={false}
                                    >
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                        Are you sure you want to delete this certificate?
                                    </DialogLayout>
                                </CardActions>
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        certificates: getCertificates(state),
    };
}

export default connect(mapStateToProps)(toJS(CertificatesCard));