import React, {PureComponent} from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles/index';

/* Place its children in a row */
/* 1 child : left */
/* 2 children : left, right */

/* 3 children : left, center, right */
class Row extends PureComponent {
    render() {
        const {classes, ...props} = this.props;
        const childrenCount = React.Children.count(this.props.children);
        return (
            <div className={classes.root} {...props}>
                {React.Children.map(this.props.children, (child, i) => (
                    <div
                        className={classNames({
                            [classes.left]: i === 0,
                            [classes.middle]: i === 1 && childrenCount > 2,
                            [classes.right]: i === 2 || i === 1 && childrenCount === 2,
                        })}
                    >
                        {child}
                    </div>
                ))}
            </div>
        )
    }
}

const styles = () => ({
    root: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },
    left: {
        flex: 1,
    },
    middle: {},
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export default withStyles(styles)(Row);