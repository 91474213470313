import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

class CertificateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const certificate = getFormData(e.target, [
            'name',
            'url',
            'startDate',
            'endDate',
            {id: this.props.certificate && this.props.certificate.id}
        ]);

        const errors = validateFormData(certificate, {
            name: 'required',
            startDate: [
                'date',
                'nonFutureDate',
                (certificate.endDate && !certificate.startDate) ? '*Required if end date is specified' : '',
            ],
            endDate: [
                'date',
                'nonFutureDate',
                (Date.parse(certificate.endDate) < Date.parse(certificate.startDate)) ? '*The end date cannot be before the start date' : '',
            ],
        });

        this.setState({errors});
        if (Object.keys(errors).length) return;
        if (!this.props.certificate || !isSubset(certificate, this.props.certificate)) this.props.onSubmit(certificate);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {certificate = {}} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField fullWidth required name="name" label="Certificate Name"
                                   defaultValue={certificate.name}
                                   error={Boolean(this.state.errors['name'])}
                                   helperText={this.state.errors['name']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth name="url" label="Certificate URL"
                                   defaultValue={certificate.url}
                                   error={Boolean(this.state.errors['url'])}
                                   helperText={this.state.errors['url']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth type="date" name="startDate" label="Start Date"
                                   defaultValue={certificate.startDate}
                                   error={Boolean(this.state.errors['startDate'])}
                                   helperText={this.state.errors['startDate']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth type="date" name="endDate" label="End Date"
                                   defaultValue={certificate.endDate}
                                   error={Boolean(this.state.errors['endDate'])}
                                   helperText={this.state.errors['endDate']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default CertificateForm;
