import React, {PureComponent} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

class LandingHero extends PureComponent {
    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.headline1}>HI TALENT,</div>
                <div className={classes.headline2}>WE CAN HELP YOU FIND THE RIGHT JOB</div>
            </div>
        );
    }
}

const styles = (theme) => ({
    headline1: {
        marginTop: 80,
        textAlign: 'center',
        color: theme.palette.common.white,
        fontSize: 20,
    },
    headline2: {
        marginTop: 8,
        textAlign: 'center',
        color: theme.palette.common.white,
        fontSize: 36,
        fontWeight: 700,
    }
});

export default withStyles(styles)(LandingHero);