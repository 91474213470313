import React, {Component} from 'react';
import Divider from '@material-ui/core/Divider';

/* Molecules */
import JobType from './molecules/JobType';
import FavoriteButton from './molecules/FavoriteButton';
import ShareButton from './molecules/ShareButton';
import JobTitle from './molecules/JobTitle';
import JobMetadata from './molecules/JobMetadata';
import CancelApplicationButton from './molecules/CancelApplicationButton';
import ApplyButton from './molecules/ApplyButton';
import ApplicationStatus from './molecules/ApplicationStatus';
import JobDescription from './molecules/JobDescription';

/* Layout */
import Layout from '../../core/layouts/Layout';
import If from '../../core/If';
import Row from '../../core/layouts/Row';
import GridRow from '../../core/layouts/GridRow';
import SwipeableTabs from '../../core/layouts/SwipeableTabs';

class Content extends Component {
    render() {
        const {
            job,
            isFavorite,
            application,
            talent,
            onFavorite: handleFavorite,
            onApply: handleApply,
            onCancelApplication: handleCancelApplication,
        } = this.props;
        if (!job) return 'loading...';

        const isWatching = application && application.status === 'Watching';
        // console.log(isWatching);
        return (
            <>
                <Layout padding>
                    <Row>
                        <JobType jobType={job.jobType} />
                        <>
                            <FavoriteButton isFavorite={isFavorite} onClick={handleFavorite} />
                            <ShareButton jobId={job.id} />
                        </>
                    </Row>
                    <JobTitle jobTitle={job.title} />
                    <GridRow>
                        <JobMetadata
                            company={job.company}
                            city={job.city}
                            province={job.province}
                            postingDate={job.postingDate}
                            id={job.id}
                        />
                        <>
                            <CancelApplicationButton onCancelApplication={handleCancelApplication}
                                                     isWatching={isWatching} />
                            <ApplyButton isWatching={isWatching} isApplied={Boolean(application)} onApply={handleApply}
                                         talent={talent} />
                        </>
                    </GridRow>
                </Layout>
                <Divider />
                <If condition={!!application}>
                    <SwipeableTabs labels={['My Status', 'Job Description']}>
                        <Layout padding>
                            <ApplicationStatus application={application} />
                        </Layout>
                        <Layout padding>
                            <JobDescription publicDesc={job.publicDesc} jdText={job.jdText} />
                        </Layout>
                    </SwipeableTabs>
                    <Layout padding>
                        <JobDescription publicDesc={job.publicDesc} jdText={job.jdText} />
                    </Layout>
                </If>
            </>
        );
    }
}

export default Content;
