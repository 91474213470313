import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Grids from '../../components/core/layouts/Grids';
import SignupForm from '../../components/forms/account/SignupForm';
import TextDivider from '../../components/core/TextDivider';
import { registerUser } from '../../actions/userActions'

class Signup extends Component {
    handleRegister = (credentials) => this.props.dispatch(registerUser(credentials));
    handleToLogin = () => {
        const { location, history } = this.props;
        history.push('/login', location.state)

    };

    render() {
        return (
            <div className="contentContainer centeredContainer">
                <Paper style={{ width: 320 }}>
                    <Grids padding={24} spacing={2}>
                        <SignupForm onSubmit={this.handleRegister} />
                        <TextDivider>Have an account already?</TextDivider>
                        <Button fullWidth color="primary" variant="outlined" onClick={this.handleToLogin}>
                            Login
                        </Button>
                    </Grids>
                </Paper>
            </div>
        );
    }
}

export default connect()(Signup);
