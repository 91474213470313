import React, {Component} from 'react';
import {connect} from 'react-redux';

import JobList from './JobList';
import toJS from 'src/app/components/hocs/toJS';
import {getJobListES} from 'src/app/actions/jobActions';
import {getAppliedJobIds} from 'src/app/reducers/controller/userReducer';
import {getSearchedJobs, getTotal} from 'src/app/reducers/controller/searchReducer';
import {getIsLoggedInOrOut} from 'src/app/reducers/controller/appReducer';

import JobSearchContext from '../JobSearchContext';

class JobListContainer extends Component {
    static contextType = JobSearchContext;
    /**
     * This method should be be passed as 'loadMore' prop into 'InfiniteScroll'.
     * Meanwhile, the following conditions should be passed as 'hasMore' prop:
     * 1. 'jobsFilter' is 'all', AND,
     * 2. 'total' is -1 (first load), OR 'total' > jobs.length (load more jobs)
     */
    handleSearch = (() => {
        let isLoading = false;
        let isInitialLoading = false;
        return () => {
            if (isLoading) return;
            isLoading = true;
            const {total} = this.props;
            const [_, {deselectJob: onDeselectJob}] = this.context;
            //console.log('[JobsList] loadmore():', `current total: ${total === -1 ? 'initial' : total}`, `isLoading: ${isLoading}`);
            if (total === -1) {
                onDeselectJob();
                isInitialLoading = true;
            }
            this.props.dispatch(getJobListES())
                .then(() => {
                    isLoading = false;
                    if (isInitialLoading) {
                        this.handleSelectFirstJob();
                        isInitialLoading = false;
                    }
                });
        }
    })();

    handleSelectFirstJob = () => {
        const {
            jobs,
            selectFirstJobOnLoad,
        } = this.props;
        const [_, {selectJob: onSelectJob}] = this.context;
        const firstJobId = jobs && jobs[0] ? jobs[0].id : -1;
        if (selectFirstJobOnLoad) onSelectJob(firstJobId);
    };

    render() {
        const [{selectedJobId}, {selectJob: onSelectJob, deselectJob: onDeselectJob}] = this.context;
        const {
            jobs,
            total,
            appliedJobIds,
            isLoggedInOrOut,
        } = this.props;
        return (
            <JobList
                selectedJobId={selectedJobId}
                onSelectJob={onSelectJob}
                onDeselectJob={onDeselectJob}
                jobs={jobs}
                total={total}
                appliedJobIds={appliedJobIds}
                disableLoading={!isLoggedInOrOut}
                onLoad={this.handleSearch}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        jobs: getSearchedJobs(state),
        total: getTotal(state),
        appliedJobIds: getAppliedJobIds(state),
        isLoggedInOrOut: getIsLoggedInOrOut(state),
    };
}

export default connect(mapStateToProps)(toJS(JobListContainer));
