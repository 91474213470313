import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import DialogLayout from 'src/app/components/core/DialogLayout';
import AdvancedSearch from './AdvancedSearch';

class AdvancedSearchFAB extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/jobs/search">
                    <DialogLayout
                        variant="responsive"
                        title="Advanced Search"
                        hideActionButton
                        hideCancelButton
                    >
                        <Fab color='primary'
                            style={{position: 'fixed', bottom: '1em', right: '1em'}}
                        >
                            <SearchIcon />
                        </Fab>
                        <AdvancedSearch />
                    </DialogLayout>
                </Route>
            </Switch>
        );
    }
}

export default AdvancedSearchFAB;
