import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

function ThemeProvider(props) {
    const themeConfig = {
        palette: {
            primary: {
                // light: '#73c9ff',
                light: '#3498DB',
                main: '#3498DB',
                dark: '#3498DB',
                // dark: '#006aa9',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#30c066',
                contrastText: '#ffffff',
            },
            darkGray: {
                main: '#3E3E3E',
            },
        },

        overrides: {
            MuiButton: {
                root: {
                    textTransform: 'none',
                },
                label: {
                    whiteSpace: 'nowrap'
                },
                contained: {
                    boxShadow: 'none',
                },
            },

            MuiIconButton: {
                root: {
                    color: '#8e8e8e',
                },
            },

            MuiDialogActions: {
                root: {
                    justifyContent: 'flex-start',
                },
            },

            MuiTabs: {
                root: {
                    boxShadow: 'inset 0 -1px #e8e8e8'
                },
                indicator: {
                    height: 3,
                }
            },

            MuiTab: {
                root: {
                    textTransform: 'initial',
                    '@media (min-width: 960px)': {
                        fontSize: '0.875rem'
                    }
                }
            },

            MuiInput: {
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #cceeff inset',
                        MozBoxShadow: '0 0 0 30px #cceeff inset',
                        borderRadius: '4px 4px 0 0'
                    }
                },
            },

            MuiDialog: {
                paperWidthSm: {
                    minWidth: 360,
                }
            }
        },

        props: {
            MuiInputLabel: {
                shrink: true,
            },
        },
    };
    const theme = createMuiTheme(themeConfig);
    console.log(theme);
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </MuiThemeProvider>
    );
}

export default ThemeProvider;
