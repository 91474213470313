import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/FavoriteBorder';
import UnfavoriteIcon from '@material-ui/icons/Favorite';

class FavoriteButton extends Component {
    render() {
        const {isFavorite, onClick} = this.props;
        return (
            <IconButton color="primary" onClick={onClick}>
                {isFavorite ? <UnfavoriteIcon /> : <FavoriteIcon />}
            </IconButton>
        );
    }
}

export default FavoriteButton;