import React, {PureComponent} from 'react';
import Grids from './Grids';

const defaultFluid = [
    {sm: 'auto'},
    {sm: 'auto'},
];
class GridRow extends PureComponent {
    render() {
        const {...props} = this.props;
        return (
            <Grids
                fluid={defaultFluid}
                spacing={1}
                alignItems="center"
                justify="space-between"
                {...props}
            />
        );
    }
}

export default GridRow;
