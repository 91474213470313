import React, {Component} from 'react';

class FormStateManager extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    handleDispatchSubmitEvent = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    getStateAndHandles() {
        return {
            formRef: this.form,
            handleDispatchSubmitEvent: this.handleDispatchSubmitEvent,
        }
    }

    render() {
        return this.props.children(this.getStateAndHandles());
    }
}

export default FormStateManager;