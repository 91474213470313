import React, {PureComponent} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import List from '@material-ui/core/List';

import Loader from '../../../core/Loader';
import NoResult from '../../JobDetail/molecules/NoResult';
import JobListItem from '../../JobListItem';

class JobList extends PureComponent {
    render() {
        const {
            total,
            jobs,
            appliedJobIds,
            selectedJobId,
            onSelectJob,
            disableLoading,
            onLoad,
        } = this.props;
        const hasMore = !disableLoading && (total === -1 || jobs.length < total);
        if(total === 0) return <NoResult />;
        return (
            <InfiniteScroll
                loadMore={onLoad}
                hasMore={hasMore}
                loader={<Loader key={0} />}
                useWindow={false}
                threshold={40}
            >
                <List disablePadding>
                    {jobs.map(job => (
                        <JobListItem
                            key={job.id}
                            onSelectJob={onSelectJob}
                            isSelected={job.id === selectedJobId}
                            job={job}
                            isApplied={appliedJobIds.indexOf(job.id) !== -1}
                        />
                    ))}
                </List>
            </InfiniteScroll>
        )
    }
}

export default JobList;
