import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import backgroundImage from 'src/images/banner-bg.png';

const LandingBackground = ({src, classes, children}) => (
    <div className={classes.root} style={{backgroundImage: `url(${backgroundImage})`,}} >{children}</div>
);

const styles = (theme) => ({
    root: {
        zIndex: -1,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: `no-repeat center center fixed`,
        backgroundSize: 'cover'
    },
});

export default withStyles(styles)(LandingBackground);