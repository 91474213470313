import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

const LOGIN_STATUS = Object.freeze({
    UNCHECKED: 'unchecked',
    LOGOUT: 'logout',
    PRE_LOGIN: 'pre-login',
    LOGIN: 'login',
});

const defaultState = Immutable.Map({
    loginStatus: LOGIN_STATUS.UNCHECKED,
});

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case ActionTypes.CHECKED_LOGIN_TOKEN:
            if (action.isLoggedIn)
                return state.set('loginStatus', LOGIN_STATUS.PRE_LOGIN);
            else
                return state.set('loginStatus', LOGIN_STATUS.LOGOUT);
        case ActionTypes.LOGIN:
            return state.set('loginStatus', LOGIN_STATUS.PRE_LOGIN);
        case ActionTypes.LOGOUT:
            return state.set('loginStatus', LOGIN_STATUS.LOGOUT);
        case ActionTypes.LOADED_USER_DATA:
            return state.set('loginStatus', LOGIN_STATUS.LOGIN);
        default:
            return state;
    }
}

const getLoginStatus = (state) => state.controller.app.get('loginStatus');

export const getIsLoginTokenChecked = createSelector(
    [getLoginStatus],
    (loginStatus) => loginStatus !== LOGIN_STATUS.UNCHECKED
);

export const getIsPreLoggedIn = createSelector(
    [getLoginStatus],
    (loginStatus) => loginStatus === LOGIN_STATUS.PRE_LOGIN
);

export const getIsLoggedIn = createSelector(
    [getLoginStatus],
    (loginStatus) => loginStatus === LOGIN_STATUS.LOGIN
);

export const getIsLoggedOut = createSelector(
    [getLoginStatus],
    (loginStatus) => loginStatus === LOGIN_STATUS.LOGOUT
);

export const getIsLoggedInOrOut = createSelector(
    [getLoginStatus],
    (loginStatus) =>loginStatus === LOGIN_STATUS.PRE_LOGIN|| loginStatus === LOGIN_STATUS.LOGIN || loginStatus === LOGIN_STATUS.LOGOUT
);
