import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import LocationIcon from '@material-ui/icons/LocationOn';
import IconInput from './IconInput';
import {getFormData} from 'src/app/utils/forms';

class LandingSearchBarForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        const keywords = getFormData(e.target, [
            'q',
            'location',
        ]);
        if (typeof this.props.onSubmit === 'function') this.props.onSubmit(keywords);
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={5} md={7}>
                        <IconInput
                            Icon={SearchIcon}
                            autoFocus={true}
                            name="q"
                            placeholder="Job Title, Skills or Keywords"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <IconInput
                            Icon={LocationIcon}
                            name="location"
                            placeholder="Location"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2}>
                        <Button fullWidth variant="contained" color="primary" type="submit">
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default LandingSearchBarForm;
