/**
 * Created by Jiayuan Deng on 7/6/18.
 */
import './style.scss';
import './images/favicon.png';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';

const render = (App) => ReactDOM.render(<App />, document.getElementById('react-app'));
render(App);

// import('./app/App').then(({default: App}) => {
//     render(App)
// });
