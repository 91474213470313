import React, {PureComponent} from 'react';
import Chip from '@material-ui/core/Chip';

class JobType extends PureComponent {
    render() {
        const {jobType} = this.props;
        return (
            <Chip label={jobType} />
        );
    }
}

export default JobType;