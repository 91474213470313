import React, {Component} from 'react';

const JobSearchContext = React.createContext({});

const Provider = JobSearchContext.Provider;

class JobSearchProvider extends Component {
    state = {
        selectedJobId: -1,
    };

    handleSelectJob = (jobId) => this.setState({selectedJobId: jobId});

    handleDeselectJob = () => this.setState({selectedJobId: -1});

    render() {
        return (
            <Provider value={[
                this.state,
                {
                    selectJob: this.handleSelectJob,
                    deselectJob: this.handleDeselectJob
                }
            ]}>
                {this.props.children}
            </Provider>
        );
    }
}

JobSearchContext.Provider  = JobSearchProvider;

export default JobSearchContext;