import React, {PureComponent} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import DialogLayout from '../../../core/DialogLayout/index';
import SharePanel from './SharePanel';

class ShareButton extends PureComponent {
    render() {
        const {jobId} = this.props;
        return (
            <DialogLayout
                title="Share job posting with others"
                onConfirm="handleCopyToClipboard"
                actionButtonText="Copy Link"
                disableCloseOnAction
            >
                <IconButton color="primary">
                    <ShareIcon />
                </IconButton>
                <SharePanel jobId={jobId} />
            </DialogLayout>
        );
    }
}

export default ShareButton;