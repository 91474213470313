import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logoImg from 'src/images/logo-en-white.png'

const Logo = ({ tenantCompanyLogo, tenantId }) => {
    if (tenantId !== '0') {
        return <Link to='/jobs/search'>
            <img src={tenantCompanyLogo || logoImg} alt="logo" height="36" />
        </Link>
    }

    return (
        <Link to='/'>
            <img src={logoImg} alt="logo" height="36" />
        </Link>
    )
};

const mapStateToProps = (state) => {
    const tenantId = state.controller.search.getIn(['keywords', 'tenantId']);
    const tenantCompanyLogo = state.model.tenants.getIn([tenantId, 'company', 'logo']);

    return {
        tenantCompanyLogo,
        tenantId
    }

};

export default connect(mapStateToProps)(Logo);