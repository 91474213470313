import {isEmail, isPhone, isDate} from './formats';

export const getFormData = (target, sources) => {
    const formData = {};
    if (!sources) return formData;
    sources.map((source) => {
        if (source) {
            if (typeof source === 'string') {
                let [key, alias] = source.split(':').map(item => item.trim());
                alias = alias || key;
                if (!target[key]) throw new Error(
                    `'getFormData' cannot find a input field with name '${alias}'.`
                );
                formData[alias] = target[key].value;
            }
            if (typeof source === 'object') {
                for (let key in source) {
                    if (source[key] === undefined) delete source[key];
                }
                Object.assign(formData, source);
            }
        }
    });
    return formData;
};

/* See implementation of _validate() for all available validators */
export const validateFormData = (formData, validators) => {
    const errors = {};
    Object.keys(formData).map((key) => {
        const value = formData[key];
        const validator = validators[key];
        let error = '';
        if (Array.isArray(validator)) {
            /* Check whether an validator returns error */
            validator.some((validator) => {
                error = _validate(value, validator);
                return error !== '';
            });
        }
        else {
            error = _validate(value, validator);
        }
        if (error) errors[key] = error;
    });
    return errors;
};

const _validate = (value, validator) => {
    switch (typeof validator) {
        case 'string':
            if (validator === 'required') return !value ? '*Required' : '';
            if (value === '') return '';
            switch (validator) {
                case 'email':
                    return !isEmail(value) ? '*Invalid email address' : '';
                case 'phone':
                    return !isPhone(value) ? '*Invalid phone number' : '';
                case 'date':
                    return !isDate(value) ? '*Invalid date format' : '';
                case 'pastDate':
                    return Date.parse(value) >= new Date().setUTCHours(0, 0, 0, 0) ? '*Date must be in the past' : '';
                case 'nonFutureDate':
                    return Date.parse(value) >= new Date().setUTCHours(24, 0, 0, 0) ? '*Date must not be in the future' : '';
            }
            return validator;
        case 'function':
            return validator(value);
        case 'boolean':
            return !validator ? 'Error' : '';
    }
};

