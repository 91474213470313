import React, {Component} from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {getTotal} from '../../../reducers/controller/searchReducer';

const title = {
    all: 'All Jobs',
    favorite: 'Favorite Jobs',
    applied: 'Applied Jobs',
};

class JobsHeader extends Component {

    render() {
        const {jobsFilter, total} = this.props;
        return (
            <Toolbar>
                <Typography variant="h6">
                    {title[jobsFilter]}
                    {jobsFilter === 'all' && total >= 0 && ` (${total})`}
                </Typography>
            </Toolbar>
        );
    }
}

const mapStateToProps = (state) => {
    const match = matchPath(state.router.location.pathname, '/jobs/:jobsFilter');
    const {jobsFilter} = match ? match.params : {};
    return {
        jobsFilter,
        total: getTotal(state),
    }
};

export default connect(mapStateToProps)(JobsHeader);
