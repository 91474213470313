import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const SearchKitLayout = ({classes, children}) => (
    <div className={classes.root}>
        {children}
    </div>
);

const styles = (theme) => ({
    root: {
        width: '960px',
        margin: '0 16px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& > :nth-child(1)': {
            flex: '1 1 0',
        },
        '& > :nth-child(2)': {
            marginLeft: 8,
            flex: '0 0 auto',
        }
    },
});

export default withStyles(styles)(SearchKitLayout);