import {combineReducers} from 'redux';
import app from './appReducer';
import user from './userReducer';
import search from './searchReducer';
import message from './messageReducer';
import iframe from './iframe';

export default combineReducers({
    app,
    user,
    search,
    message,
    iframe
});