import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { getIsLoggedIn } from '../../../reducers/controller/appReducer';
import { getJob } from '../../../reducers/model/jobsReducer';
import { getFavoriteJobIds, getApplication, getCurrentTalent } from '../../../reducers/controller/userReducer';
import { createApplication, deleteApplication } from '../../../actions/userActions';
import { favoriteJob, getJobDetail, unfavoriteJob } from '../../../actions/jobActions';
import { updateTalent } from '../../../actions/talentActions';
import toJS from '../../../components/hocs/toJS';

import NoJobSelected from './molecules/NoResult';
import Content from './Content';
import FullscreenDialog from '../../core/DialogLayout/FullscreenDialog';

class JobDetailContainer extends Component {
    componentDidMount() {
        this.handleLoadJob();
    }

    componentDidUpdate(prevProps) {
        if (this.props.jobId !== prevProps.jobId) this.handleLoadJob();
    };

    handleToggleFavorite = () => {
        const { jobId, isLoggedIn, isFavorite } = this.props;
        if (!isLoggedIn) {
            this.props.dispatch(push(`/login`));
            return;
        }
        if (isFavorite) {
            this.props.dispatch(unfavoriteJob(jobId));
        } else {
            this.props.dispatch(favoriteJob(jobId));
        }
    };

    handleApply = ({ application, talent }) => {
        application.jobId = this.props.jobId;
        if (talent)
            this.props.dispatch(updateTalent(talent))
                .then(() => this.props.dispatch(createApplication(application)));
        else
            this.props.dispatch(createApplication(application));
    };

    handleCancelApplication = () => {
        this.props.dispatch(deleteApplication(this.props.application));
    };

    handleLoadJob = () => {
        if (this.props.jobId < 0) return;
        this.props.dispatch(getJobDetail(this.props.jobId))
            .then((job) => document.title = job.title);
    };

    render() {
        const {
            onClose,
            jobId,
            job,
            isFavorite,
            application,
            talent,
        } = this.props;

        if (onClose) {
            return <FullscreenDialog
                disablePadding
                hideActionButton
                open={jobId > 0}
                onClose={onClose}
            >
                <Content
                    job={job}
                    isFavorite={isFavorite}
                    talent={talent}
                    application={application}
                    onFavorite={this.handleToggleFavorite}
                    onApply={this.handleApply}
                    onCancelApplication={this.handleCancelApplication}
                />
            </FullscreenDialog>
        }

        if (jobId < 0) return <NoJobSelected />;

        return (
            <Content
                job={job}
                isFavorite={isFavorite}
                talent={talent}
                application={application}
                onFavorite={this.handleToggleFavorite}
                onApply={this.handleApply}
                onCancelApplication={this.handleCancelApplication}
            />
        );
    }
}

const mapStateToProps = (state, { jobId }) => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        talent: getCurrentTalent(state),
        jobId,
        job: getJob(state, jobId),
        application: getApplication(state, jobId),
        isFavorite: getFavoriteJobIds(state).indexOf(jobId) !== -1,
    };
};

export default connect(mapStateToProps)(toJS(JobDetailContainer));
