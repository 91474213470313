import React, {Component} from 'react';
import {connect} from 'react-redux';
import {uploadResume} from '../../actions/talentActions';

import {
    Button,
    CircularProgress
} from '@material-ui/core';

class UploadResumeButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        };
    }

    uploadResume = (file) => {
        if (!file) return;
        this.setState({uploading: true});
        this.props.dispatch(uploadResume(file))
            .then(resume => {
                this.setState({
                    uploading: false,
                });
                return resume;
            })
            .catch(() => {
                this.setState({
                    uploading: false
                });
            });
    };

    render() {
        const {fullWidth, variant, color} = this.props;
        const buttonProps = {
            fullWidth,
            variant,
            color
        };
        return (
            <Button {...buttonProps} component="label">
                {this.state.uploading ? (
                    <CircularProgress
                        color="inherit"
                        size={20}
                        className="buttonCircularProgress" />
                ) : 'Upload a new resume'
                }
                <input
                    type="file"
                    style={{display: 'none'}}
                    onChange={e => this.uploadResume(e.target.files[0])}
                />
            </Button>
        );
    }
}

export default connect()(UploadResumeButton);