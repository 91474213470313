import React from 'react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';

const NavigationLayout = ({transparent, classes, children}) => (
    <AppBar position="sticky" className={classNames(classes.root, {[classes.transparent]: transparent})}>
        <Toolbar className={classes.toolbar}>
            {children}
        </Toolbar>
    </AppBar>
);

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.darkGray.main,
    },
    transparent: {
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between;',
    },
});

export default withStyles(styles)(NavigationLayout);