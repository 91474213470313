import React, {Component} from 'react';
import {connect} from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {resetPassword} from '../../actions/userActions';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: false
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.handleResetPassword(e.target.email.value);
    };

    handleResetPassword = (email) => {
        this.setState({reset: true});
        this.props.dispatch(resetPassword(email));
    };

    handleGoBack = () => {
        this.setState({reset: false});
    };

    render() {
        return (
            <div className="contentContainer centeredContainer">
                <Card style={{width: 320}}>
                    {!this.state.reset ?
                        <CardContent>
                            <form onSubmit={this.onSubmit}>
                                <TextField fullWidth required name="email" label="Email" />
                                <br /><br />
                                <Button fullWidth variant="contained" color="primary" type='submit'>
                                    Reset Password
                                </Button>
                            </form>
                        </CardContent> :
                        <CardContent>
                            <Typography variant="title" gutterBottom>
                                A mail has been sent to you.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Please check your emails for details on how to reset your password.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                If you don't receive the reset email, check the spam folder of your inbox.
                                Please allow up to 24 hours to receive the email.
                                If you still don't see the email, please request that another email be sent to you.
                            </Typography>
                            <br />
                            <Button fullWidth variant="contained" color="primary" onClick={this.handleGoBack}>
                                Resend the Email
                            </Button>
                        </CardContent>
                    }
                </Card>
            </div>
        )
    }
}

export default connect()(ResetPassword);