import * as apnSDK from '../../apn-sdk';
import apnRequest from '../../apn-sdk/request';
import * as ActionTypes from '../constants/actionTypes';
import * as talentActions from './talentActions';
import { getCurrentTalentId } from '../reducers/controller/userReducer';

import { normalize, schema } from 'normalizr';

const job = new schema.Entity('jobs');
const user = new schema.Entity('users');
const application = new schema.Entity('applications', {
    job, user
});

export const login = ({ email, password }) => (dispatch) => {
    const credentials = {
        username: email,
        password,
    };
    return apnRequest.login(credentials)
        .then(() => {
            dispatch({
                type: ActionTypes.LOGIN,
            });
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message,
                    type: 'error'
                }
            })
        })
};

export const loginBySocialAccount = (code, provider, redirect_uri) => (dispatch, getState) => {
    return apnRequest.loginWithOauth2Code(code, provider, redirect_uri)
        .then(() => {
            dispatch({
                type: ActionTypes.LOGIN,
            });
        });
};

export const checkLoginToken = () => (dispatch) => {
    return apnRequest.checkLoginToken()
        .then((isLoggedIn) => {
            dispatch({
                type: ActionTypes.CHECKED_LOGIN_TOKEN,
                isLoggedIn,
            });
            return isLoggedIn;
        });
};

export const logout = () => {
    return (dispatch) => {
        return apnRequest.logout()
            .then(success => {
                if (success) {
                    dispatch({
                        type: ActionTypes.LOGOUT,
                    });
                }
                return success
            })
    }
};

export const registerUser = (credentials) => (dispatch) => {
    return apnRequest.register(credentials)
        .then((success) => {
            success && dispatch({
                type: ActionTypes.LOGIN,
            });
            return success;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
        })
};

export const resetPassword = (email) => (dispatch) => {
    return apnRequest.resetPassword(email)
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
            throw e;
        });
};

export const getCurrentUser = () => (dispatch) => {
    return apnSDK.getCurrentUser()
        .then(({ response: user }) => {
            dispatch({
                type: ActionTypes.RECEIVE_USER,
                user: user,
            });
            return user;
        })
        .catch(err => {
            throw err
        })
};

export const getMyTalent = (user) => (dispatch) => {
    return apnSDK.getMyTalent()
        .then(({ response }) => {
            if (response.length) {
                const talent = response[0];
                talent.applications = talent.applications || [];
                const normalizedData = normalize(talent.applications, [application]);
                dispatch({
                    type: ActionTypes.RECEIVE_TALENT,
                    talent,
                    appliedJobIds: Object.keys(normalizedData.entities.jobs || {}).map(idStr => parseInt(idStr)),
                    jobs: normalizedData.entities.jobs || {}
                });
                return talent
            }
            else {
                return dispatch(talentActions.createTalent({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    fullName: user.firstName + ' ' + user.lastName,
                    talentType: 'Public_Talent'
                }));
            }
        })
        .then(() => dispatch(talentActions.getResumesByTalentId()))
};

export const createApplication = (application) => (dispatch, getState) => {
    application.status = 'Watching';
    application.talentId = getCurrentTalentId(getState());
    return apnSDK.createApplication(application)
        .then(({ response }) => {
            dispatch({
                type: ActionTypes.RECEIVE_APPLICATION,
                talentId: response.talentId.toString(),
                application: response,
                jobId: response.jobId,
            });
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Application submitted',
                    type: 'success'
                }
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
            throw e;
        });
};

export const updateApplication = (application) => (dispatch, getState) => {

    return apnSDK.updateApplication(application)
        .then(({ response }) => {
            dispatch({
                type: ActionTypes.RECEIVE_APPLICATION,
                talentId: response.talentId.toString(),
                application: response,
            });
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Application updated',
                    type: 'success'
                }
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
            throw e;
        });
};

export const deleteApplication = (application) => (dispatch, getState) => {
    if (application.status !== 'Watching') return;
    const talentId = getCurrentTalentId(getState());
    return apnSDK.deleteApplication(application)
        .then(({ response }) => {
            dispatch({
                type: ActionTypes.DELETE_APPLICATION,
                application: application,
                talentId
            });
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: 'Application cancelled',
                    type: 'success'
                }
            });
            return response;
        })
        .catch(e => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    message: e.message || e,
                    type: 'error'
                }
            });
            throw e;
        });
};
