import React, { Component } from 'react';
import { connect } from 'react-redux';

import Loader from './Loader';
import * as ActionTypes from '../../constants/actionTypes';
import { checkLoginToken, getCurrentUser, getMyTalent } from '../../actions/userActions';
import { getFavoriteJobs, getAppliedJobs } from '../../actions/jobActions';
import { getIsLoginTokenChecked, getIsLoggedIn, getIsPreLoggedIn } from '../../reducers/controller/appReducer';
import { getTenantList } from '../../actions/tenantActions';

class AppLoader extends Component {
    componentDidMount() {
        this.props.dispatch(checkLoginToken());
        this.props.dispatch(getTenantList());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isPreLoggedIn } = this.props;
        if (isPreLoggedIn) {
            this.props.dispatch(getCurrentUser())
                .then(user => this.props.dispatch(getMyTalent(user)))
                .then(() => this.props.dispatch(getAppliedJobs()))
                .then(() => this.props.dispatch(getFavoriteJobs()))
                .then(() => this.props.dispatch({ type: ActionTypes.LOADED_USER_DATA }));
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    isLoginTokenChecked: getIsLoginTokenChecked(state),
    isLoggedIn: getIsLoggedIn(state),
    isPreLoggedIn: getIsPreLoggedIn(state),
});

export default connect(mapStateToProps)(AppLoader);
