import {combineReducers} from 'redux';
import users from './usersReducer';
import jobs from './jobsReducer';
import talents from './talentsReducer';
import tenants from './tenantsReducer';

export default combineReducers({
    users,
    jobs,
    talents,
    tenants,
});