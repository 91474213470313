import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/userActions';

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import TextDivider from '../../components/core/TextDivider';
import Grids from '../../components/core/layouts/Grids';
import LoginForm from '../../components/forms/account/LoginForm';

// const oauth2RedirectUrl = encodeURIComponent(`${window.location.origin}/auth/callback`);

class Login extends Component {

    handleLogin = (credentials) => {
        return this.props.dispatch(login(credentials))
        // .then(() => this.props.dispatch(goBack()));
    };

    // handleLinkedinLogin = () => {
    //     const clientid = '867626oins94xy';
    //     const scope = 'r_basicprofile r_emailaddress';
    //     const state = `linkedin_${this.props.tenantId}`;
    //     const url = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${clientid}&redirect_uri=${oauth2RedirectUrl}&state=${state}&scope=${scope}`;
    //     window.location.replace(url);
    // };

    // handleWechatLogin = () => {
    //     const appid = 'wxcb4b014db96d64c3';
    //     const scope = 'snsapi_login';
    //     const state = `wechat_${this.props.tenantId}`;
    //     const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${oauth2RedirectUrl}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    //     window.location.replace(url);
    // };

    handleToSignUp = () => {
        const { location, history } = this.props;
        history.push('/signup', location.state)

    };

    render() {
        return (
            <div className="contentContainer centeredContainer">
                <Paper style={{ width: 320 }}>
                    <Grids padding={24} spacing={2}>
                        <LoginForm onSubmit={this.handleLogin} />
                        <Button fullWidth color="primary" variant="outlined" component={Link} to={`/password`}>
                            Reset Password
                        </Button>
                        {/*<TextDivider>Login with</TextDivider>*/}
                        {/*<Button fullWidth color="primary" variant="outlined" onClick={this.handleLinkedinLogin}>*/}
                        {/*    LinkedIn Profile*/}
                        {/*</Button>*/}
                        <TextDivider>Not a member?</TextDivider>
                        <Button fullWidth color="primary" variant="outlined" onClick={this.handleToSignUp}>
                            Sign Up
                        </Button>
                    </Grids>
                </Paper>
            </div>
        )
    }
}

export default connect()(Login);
