import React from 'react';
import {Iterable} from 'immutable';

const isImmutable = Iterable.isIterable;

const toJS = Component => {
    const memoizedInputProps = {};
    const memoizedOutputProps = {};
    return props => {
        const outputProps = Object.entries(props).reduce((outputProps, [k, v]) => {
            if (memoizedInputProps[k] !== v) {
                memoizedInputProps[k] = v;
                memoizedOutputProps[k] = isImmutable(v) ? v.toJS() : v;
            }
            outputProps[k] = memoizedOutputProps[k];
            return outputProps;
        }, {});
        return <Component {...outputProps} />
    };
};

export default toJS;