import React, { Component } from 'react';
import { getFormData, validateFormData } from '../../../utils/forms';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PrimaryButton from './../../partials/PrimaryButton';

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            processing: false
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const credentials = getFormData(e.target, [
            'email',
            'password',
            'lastName',
            'firstName',
            { fullName: e.target.firstName.value + ' ' + e.target.lastName.value },
        ]);

        const errors = validateFormData(credentials, {
            email: ['required', 'email'],
            password: 'required',
        });

        this.setState({ errors });
        if (Object.keys(errors).length) return;
        this.setState({ processing: true });
        this.props.onSubmit(credentials)
            .then(() => this.setState({ processing: false }));
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth required name="email" label="Email"
                            error={Boolean(this.state.errors['email'])}
                            helperText={this.state.errors['email']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth required name="password" label="Password" type="password"
                            error={Boolean(this.state.errors['password'])}
                            helperText={this.state.errors['password']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth name="lastName" label="Last Name"
                            error={Boolean(this.state.errors['lastName'])}
                            helperText={this.state.errors['lastName']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth name="firstName" label="First Name"
                            error={Boolean(this.state.errors['firstName'])}
                            helperText={this.state.errors['firstName']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton fullWidth
                                       processing={this.state.processing}
                                       onClick={this.handleSubmit}>
                            Sign Up
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default SignupForm;
