import React, {Component} from 'react';
import {connect} from 'react-redux';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Phone from '@material-ui/icons/Phone';
import Work from '@material-ui/icons/Work';
import Home from '@material-ui/icons/Home';
import Print from '@material-ui/icons/Print';
import Email from '@material-ui/icons/Email';

import DialogLayout from '../core/DialogLayout';
import ContactForm from '../forms/profile/ContactForm';

import {contactTypes} from '../../constants/formOptions';
import {getContacts} from '../../reducers/controller/userReducer';
import toJS from '../hocs/toJS';
import {createContact, updateContact, deleteContact} from '../../actions/talentActions';

import DialogStateManager from '../core/stateManagers/DialogStateManager';
import FormDialog from '../core/DialogLayout/FormDialog';
import BasicDialog from '../core/DialogLayout/BasicDialog';

const Icons = {
    Primary_Phone: Phone,
    Cell_Phone: Phone,
    Work_Phone: Work,
    Home_Phone: Home,
    Fax: Print,
    Primary_Email: Email,
    Email: Email,
    Phone: Phone
};

const CreateContactButton = ({onSubmit}) => (
    <DialogStateManager>
        {({mount, open, handleMountAndOpen, handleClose, handleUnmount}) => <>
            <IconButton disabled={mount} onClick={handleMountAndOpen}>
                <AddIcon />
            </IconButton>
            {mount && (
                <FormDialog
                    title="Add Contact"
                    open={open}
                    onClose={handleClose}
                    onExited={handleUnmount}
                >
                    <ContactForm
                        onSubmit={onSubmit}
                    />
                </FormDialog>
            )}
        </>}
    </DialogStateManager>
);

const UpdateContactButton = ({contact, onSubmit}) => (
    <DialogStateManager>
        {({mount, open, handleMountAndOpen, handleClose, handleUnmount}) => <>
            <IconButton disabled={mount} onClick={handleMountAndOpen}>
                <EditIcon />
            </IconButton>
            {mount && (
                <FormDialog
                    title="Update Contact"
                    open={open}
                    onClose={handleClose}
                    onExited={handleUnmount}
                >
                    <ContactForm
                        contact={contact}
                        onSubmit={onSubmit}
                    />
                </FormDialog>
            )}
        </>}
    </DialogStateManager>
);

const DeleteContactButton = ({contact, onDelete}) => (
    <DialogStateManager>
        {({mount, open, handleMountAndOpen, handleClose, handleUnmount}) => <>
            <IconButton disabled={mount} onClick={handleMountAndOpen}>
                <DeleteIcon />
            </IconButton>
            {mount && (
                <BasicDialog
                    title="Delete contact?"
                    actionButtonText="Delete"
                    onAction={() => onDelete(contact)}
                    open={open}
                    onClose={handleClose}
                    onExited={handleUnmount}
                />
            )}
        </>}
    </DialogStateManager>
);

class ContactsCard extends Component {

    handleCreateContact = (contact) => this.props.dispatch(createContact(contact));

    handleUpdateContact = (contact) => this.props.dispatch(updateContact(contact));

    handleDeleteContact = (contact) => this.props.dispatch(deleteContact(contact));

    render() {
        const {contacts} = this.props;
        const addContactButton = (
            <CreateContactButton onSubmit={this.handleCreateContact} />
        );
        return (
            <Card>
                <CardHeader
                    title="Contacts"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Create Contact"
                            actionButtonText="Submit"
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <ContactForm
                                onSubmit={this.handleCreateContact}
                            />
                        </DialogLayout>
                    }
                />
                {contacts.map((contact, index) => {
                    const type = contact.type;
                    //const detail = contact.get('details');
                    const number = contact.contact;
                    const Icon = Icons[type] || Icons['Phone'];
                    return (
                        <div key={index}>
                            <Divider />
                            <div className="flex spaceBetween">
                                <CardHeader
                                    avatar={<Icon style={{color: '#8e8e8e'}} />}
                                    title={contactTypes[type].label}
                                    subheader={number}
                                />
                                <CardActions>
                                    <DialogLayout
                                        variant="form"
                                        title="Update Contact"
                                        actionButtonText="Save"
                                    >
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                        <ContactForm
                                            onSubmit={this.handleUpdateContact}
                                            contact={contact}
                                        />
                                    </DialogLayout>
                                    <DialogLayout
                                        onConfirm={() => this.handleDeleteContact(contact)}
                                        actionButtonText="Delete"
                                        disableCloseOnAction={false}
                                    >
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                        Are you sure you want to delete this contact?
                                    </DialogLayout>
                                </CardActions>
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        contacts: getContacts(state),
    };
}

export default connect(mapStateToProps)(toJS(ContactsCard));