import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import UploadResumeButton from '../core/UploadResumeButton';
import {getFormData, validateFormData} from '../../utils/forms';
import {isSubset} from '../../utils/general';

class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        const {resumes} = props.talent;
        this.state = {
            selectedResumeId: (resumes && resumes.length > 0) ? resumes[resumes.length - 1].id : 0,
            errors: {},
        };
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const resumes = this.props.talent && this.props.talent.resumes;
        if (prevProps.talent.resumes !== resumes) {
            this.setState((state) => ({
                ...state,
                selectedResumeId: (resumes && resumes.length > 0) ? resumes[resumes.length - 1].id : 0,
            }));
        }
    }

    handleResumeChange = (e) => {
        this.setState({
            selectedResumeId: e.target.value
        });
    };

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const application = getFormData(e.target, [
            'note: memo',
            {resumeId: this.state.selectedResumeId}
        ]);
        const talent = getFormData(e.target, [
            'firstName',
            'lastName',
            'email',
            'phone',
        ]);

        const errors = Object.assign(
            validateFormData(application, {
                resumeId: 'required',
            }),
            validateFormData(talent, {
                firstName: 'required',
                lastName: 'required',
                email: ['required', 'email'],
                phone: ['required', 'phone'],
            })
        );

        this.setState({errors});
        if (Object.keys(errors).length) return;

        if (isSubset(talent, this.props.talent))
            this.props.onSubmit({application});
        else
            this.props.onSubmit({application, talent});

        this._handleTryToCloseDialog();
    };

    _handleTryToCloseDialog = () => {
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {talent} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="firstName" label="First Name"
                                   defaultValue={talent.firstName}
                                   error={Boolean(this.state.errors['firstName'])}
                                   helperText={this.state.errors['firstName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="lastName" label="Last Name"
                                   defaultValue={talent.lastName}
                                   error={Boolean(this.state.errors['lastName'])}
                                   helperText={this.state.errors['lastName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="email" label="Email"
                                   defaultValue={talent.email}
                                   error={Boolean(this.state.errors['email'])}
                                   helperText={this.state.errors['email']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="phone" label="Phone"
                                   defaultValue={talent.phone}
                                   error={Boolean(this.state.errors['phone'])}
                                   helperText={this.state.errors['phone']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth multiline rows={4} name="note" label="Note"
                                   defaultValue={talent.memo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            select fullWidth required label="Resume"
                            value={this.state.selectedResumeId}
                            onChange={this.handleResumeChange}
                            error={Boolean(this.state.errors['resumeId'])}
                            helperText={this.state.errors['resumeId']}
                            disabled={!this.state.selectedResumeId}
                        >
                            {!this.state.selectedResumeId && (
                                <MenuItem
                                    key={0}
                                    value={0}
                                >
                                    No resume
                                </MenuItem>
                            )}
                            {talent.resumes.map(resume => (
                                <MenuItem
                                    key={resume.id}
                                    value={resume.id}
                                >
                                    {resume.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item container xs={12} sm={4} alignItems="flex-end">
                        <Grid item xs={12}>
                            <UploadResumeButton fullWidth variant="outlined" color="primary" />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default ApplicationForm;
