import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {getFormData} from 'src/app/utils/forms';

class AdvancedSearchForm extends Component {
    form = React.createRef();

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();
        const keywords = getFormData(e.target, [
            'q',
            'title',
            'tenantId',
            'city',
            'province',
            'country',
        ]);

        if (typeof this.props.onSubmit === 'function') this.props.onSubmit(keywords);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {keywords, tenants} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField fullWidth name='q' label="Job Title, Company or Keywords"
                                   defaultValue={keywords.q} />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField fullWidth name='title' label="Title"
                                   defaultValue={keywords.title} />
                    </Grid>
                    {/*<Grid item xs={12} sm={4}>*/}
                    {/*    <TextField select fullWidth name='tenantId' label="Posted By"*/}
                    {/*               defaultValue={keywords.tenantId}*/}
                    {/*               SelectProps={{native: true}}*/}
                    {/*    >*/}
                    {/*        <option key={0} value={0}>*/}
                    {/*            All*/}
                    {/*        </option>*/}
                    {/*        {Object.values(tenants).map((tenant) => (*/}
                    {/*            <option key={tenant.id} value={tenant.id}>*/}
                    {/*                {tenant.name}*/}
                    {/*            </option>*/}
                    {/*        ))}*/}
                    {/*    </TextField>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name='city' label="City"
                                   defaultValue={keywords.city} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name='province' label="State"
                                   defaultValue={keywords.province} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name='country' label="Country"
                                   defaultValue={keywords.country} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth type="submit" variant="contained" color="primary">Search</Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default AdvancedSearchForm;
