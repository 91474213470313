import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

const defaultState = Immutable.Map({
    params: {},
});

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case ActionTypes.CONFIG_IFRAME_PARAMS:
            return state.set('params', action.params);
        default:
            return state;
    }
}

export const getIframeParams = (state) => state.controller.iframe.get('params');