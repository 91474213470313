import React, {Component} from 'react';
import JobDetail from '../JobDetail';
import JobSearchContext from './JobSearchContext';

class SelectedJobDetail extends Component {
    static contextType = JobSearchContext;
    render() {
        const [{selectedJobId}] = this.context;
        const {} = this.props;
        return (
            <JobDetail jobId={selectedJobId} />
        );
    }
}

export default SelectedJobDetail;
