import React, {Component} from 'react';

class DialogStateManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mount: false,
            open: false,
        }
    }

    handleMountAndOpen = () => {
        this.setState({mount: true, open: true});
    };

    handleClose = () => {
        this.setState(state => ({...state, open: false}));
    };

    handleUnmount = () => {
        this.setState(state => ({...state, mount: false}));
    };

    getStateAndHandles() {
        return {
            ...this.state,
            handleMountAndOpen: this.handleMountAndOpen,
            handleClose: this.handleClose,
            handleUnmount: this.handleUnmount,
        }
    }

    render() {
        return this.props.children(this.getStateAndHandles());
    }
}

export default DialogStateManager;