import React, {Component} from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Button from '@material-ui/core/Button';
import {getIsLoggedIn} from '../../reducers/controller/appReducer';

class PrivateButton extends Component {
    handleClick = () => {
        const {isLoggedIn} = this.props;
        if (!isLoggedIn) {
            this.props.dispatch(push(`/login`));
        }
        else {
            this.props.onClick();
        }
    };

    render() {
        const {isLoggedIn, onClick, dispatch, ...props} = this.props;
        return (
            <Button {...props} onClick={this.handleClick} />
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps)(PrivateButton);
