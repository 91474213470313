import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsLoggedIn, getIsPreLoggedIn } from '../../reducers/controller/appReducer';
import { getPathname } from '../../reducers/routing';
import { isPathMatch } from '../../utils/general';

class If extends Component {
    render() {
        const {
            not,
            path,
            loggedIn,
            condition: conditionProp = true,
            pathname,
            isLoggedIn,
            isPreLoggedIn,
            children
        } = this.props;
        let condition = conditionProp;
        if (path) condition = condition && isPathMatch(pathname, path);
        if (loggedIn) condition = condition && (isLoggedIn || isPreLoggedIn);
        if (not) condition = !condition;
        return React.Children.toArray(children)[condition ? 0 : 1] || null;
    }
}

function mapStateToProps(state) {
    return {
        pathname: getPathname(state),
        isLoggedIn: getIsLoggedIn(state),
        isPreLoggedIn: getIsPreLoggedIn(state),
    };
}

export default connect(mapStateToProps)(If);
