import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import BackIcon from '@material-ui/icons/ChevronLeft';

import TripleItemsAppBar from '../layouts/TripleItemsAppBar';

const SlideTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class FullscreenDialog extends Component {
    render() {
        const {
            title = '',
            disablePadding,
            hideCancelButton,
            hideActionButton,
            actionButtonText = 'Accept',
            onAction = () => {
            },
            disableCloseOnAction,
            children,
            ...props
        } = this.props;
        const {
            onClose = () => {
            },
        } = props;

        console.log(props);
        return (
            <Dialog
                {...props}
                fullScreen
                TransitionComponent={SlideTransition}
                PaperProps={{ style: { WebkitOverflowScrolling: 'touch' } }}
            >
                <TripleItemsAppBar>
                    <IconButton color="inherit" onClick={onClose}>
                        <BackIcon fontSize='large' />
                    </IconButton>
                    <Typography variant="h6" color="inherit" align="center">
                        {title}
                    </Typography>
                    {!hideActionButton && (
                        <Button color="inherit" onClick={() => {
                            onAction();
                            !disableCloseOnAction && onClose();
                        }}>
                            {actionButtonText}
                        </Button>
                    )}
                </TripleItemsAppBar>
                {!disablePadding ? (
                    <DialogContent>
                        <br />
                        {children}
                    </DialogContent>
                ) : <>{children}</>
                }
            </Dialog>
        );
    }
}

export default FullscreenDialog;
