import React, {PureComponent} from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class NoResult extends PureComponent {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h3" color='textSecondary'>No result</Typography>
            </div>
        );
    }
}

export default withStyles(styles)(NoResult);
