import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DialogLayout from '../core/DialogLayout';
import ExperienceForm from '../forms/profile/ExperienceForm';

import {createExperience, updateExperience, deleteExperience} from '../../actions/talentActions';
import {getExperiences} from '../../reducers/controller/userReducer';
import toJS from '../hocs/toJS';

class ExperiencesCard extends Component {
    handleCreateExperience = (experience) => this.props.dispatch(createExperience(experience));

    handleUpdateExperience = (experience) => this.props.dispatch(updateExperience(experience));

    handleDeleteExperience = (experience) => this.props.dispatch(deleteExperience(experience.id));

    render() {
        const {experiences} = this.props;
        return (
            <Card>
                <CardHeader
                    title="Experiences"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Create Experience"
                            actionButtonText="Submit"
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <ExperienceForm
                                onSubmit={this.handleCreateExperience}
                            />
                        </DialogLayout>
                    }
                />
                {experiences.map((experience, index) => {
                    const startDate = experience.startDate;
                    const endDate = experience.endDate;
                    const start = startDate ? moment(startDate).format('L') + ' - ' : '';
                    const end = endDate ? moment(endDate).format('L') : 'Present';
                    return (
                        <div key={index}>
                            <Divider />
                            <div className="flex spaceBetween">
                                <CardContent>
                                    <Typography variant='body2'>{experience.title}</Typography>
                                    <Typography variant='body1'>{experience.company}</Typography>
                                    <Typography variant='caption'>{start + end}</Typography>
                                </CardContent>
                                <CardActions>
                                    <DialogLayout
                                        variant="form"
                                        title="Update Experience"
                                        actionButtonText="Save"
                                    >
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                        <ExperienceForm
                                            onSubmit={this.handleUpdateExperience}
                                            experience={experience}
                                        />
                                    </DialogLayout>
                                    <DialogLayout
                                        onConfirm={() => this.handleDeleteExperience(experience)}
                                        actionButtonText="Delete"
                                        disableCloseOnAction={false}
                                    >
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                        Are you sure you want to delete this experience?
                                    </DialogLayout>
                                </CardActions>
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        experiences: getExperiences(state),
    };
}

export default connect(mapStateToProps)(toJS(ExperiencesCard));