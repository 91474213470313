import React, {Component} from 'react';

import Responsive from 'src/app/components/core/Responsive';
import SearchKitLayout from './SearchKitLayout';
import SearchBar from './SearchBar';
import AdvancedSearchFAB from './AdvancedSearchFAB';
import AdvancedSearchLink from './AdvancedSearchLink';

class NavigationSearchKit extends Component {
    render() {
        return (
            <Responsive
                small={
                    <AdvancedSearchFAB />
                }
                large={
                    <SearchKitLayout>
                        <SearchBar />
                        <AdvancedSearchLink />
                    </SearchKitLayout>
                }
            />
        );
    }
}

export default NavigationSearchKit;