import React, {PureComponent} from 'react';
import {Parser} from 'html-to-react';
import Typography from '@material-ui/core/Typography';

const parser = new Parser();

class JobDescription extends PureComponent {
    render() {
        const {publicDesc, jdText} = this.props;
        if (publicDesc) try {
            return (
                <Typography component="div" variant='body2'>
                    {parser.parse(publicDesc)}
                </Typography>
            );
        } catch (error) {}
        return (
            <Typography paragraph style={{whiteSpace: 'pre-wrap'}} variant='body2'>
                {jdText}
            </Typography>
        );
    }
}

export default JobDescription;
