import React, {PureComponent} from 'react';
import Typography from '@material-ui/core/Typography';

class JobTitle extends PureComponent {
    render() {
        const {jobTitle} = this.props;
        return (
            <Typography variant="h6" style={{wordBreak: 'break-all'}}>
                {jobTitle}
            </Typography>
        );
    }
}

export default JobTitle;
