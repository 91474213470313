import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';

import Avatar from './Avatar';
import DialogLayout from '../core/DialogLayout';
import TalentForm from '../forms/profile/TalentForm';

import {updateTalent} from '../../actions/talentActions';
import {getBasicInfo} from '../../reducers/controller/userReducer';
import toJS from '../hocs/toJS';

class BasicInfoCard extends Component {
    handleUpdateBasicInfo = (talent) => this.props.dispatch(updateTalent(talent));

    render() {
        const {talent} = this.props;
        return (
            <Card>
                <CardHeader
                    title="Basic Info"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Update Basic Info"
                            actionButtonText="Save"
                        >
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                            <TalentForm
                                onSubmit={this.handleUpdateBasicInfo}
                                talent={talent}
                            />
                        </DialogLayout>

                    }
                />
                <CardContent>
                    <div className="centeredContainer">
                        <Avatar src={talent.photoUrl} fullName={talent.fullName} id={talent.id} />
                    </div>
                    <Typography variant="h5" align="center">{talent.fullName}</Typography>
                    <Typography align="center">
                        {(talent.title || '') + (talent.title && talent.company ? ' at ' : '') + (talent.company || '')}
                    </Typography>
                    <Typography align="center">
                        {(talent.city || '') + (talent.city && talent.province ? ', ' : '') + (talent.province || '')}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        talent: getBasicInfo(state),
    };
}

export default connect(mapStateToProps)(toJS(BasicInfoCard));
