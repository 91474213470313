import React, { Component } from 'react';

import JobDetail from '../JobDetail';
import JobSearchContext from './JobSearchContext';

class SelectedJobDetailWithDialog extends Component {
    static contextType = JobSearchContext;

    render() {
        const [{ selectedJobId }, { deselectJob }] = this.context;
        return (

            <JobDetail jobId={selectedJobId} onClose={deselectJob} />
        );
    }
}

export default SelectedJobDetailWithDialog;
