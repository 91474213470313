import React, { Component } from 'react'
import { Link } from "react-router-dom";

class NotFound extends Component {
    render() {
        return (
            <article>
                <h1>Page not found.</h1>
                <Link to="/">Home</Link>
            </article>
        );
    }
}

export default NotFound
