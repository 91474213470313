import React, {Component, Fragment} from 'react';
import moment from 'moment';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {withWidth} from '@material-ui/core/';
import {withTheme} from '@material-ui/core/styles';
import AcceptIcon from '@material-ui/icons/CheckCircle';
import RejectIcon from '@material-ui/icons/Error';


const labels = [
    'Applied',
    // 'Submitted to AM',
    // 'Submitted to Client',
    'Interview',
    'Offered',
];

const getStep = (application) => {
    const {status, lastModifiedDate, user} = application;
    const {firstName: recruiterName} = user;
    const formattedDate = moment(lastModifiedDate).fromNow();
    switch (status) {
        case 'Watching':
            return {
                step: 0,
                isRejected: false,
                message: `You applied to this job at ${formattedDate}.`,
            };
        case 'Applied':
        case 'Called_Candidate':
        case 'Meet_Candidate_In_Person':
        case 'Qualified':
            return {
                step: 1,
                isRejected: false,
                message: `Your resume is submitted to account manager by recruiter ${recruiterName} at ${formattedDate}.`,
            };
        case 'Internal_Rejected':
            return {
                step: 1,
                isRejected: true,
                message: `Unfortunately, we will not be moving your application forward at this time.`,
            };
        case 'Submitted':
        case 'Shortlisted_By_Client':
            return {
                step: 2,
                isRejected: false,
                message: `Your resume is submitted to our client at ${formattedDate}.`,
            };
        case 'Interview':
            return {
                step: 3,
                isRejected: false,
                message: `Interview at ${formattedDate}.`,
            };
        case 'Client_Rejected':
            return {
                step: 3,
                isRejected: true,
                message: `Unfortunately, our client decides not moving your application forward at this time.`,
            };
        case 'Offered':
        case 'Offer_Accepted':
        case 'Offer_Rejected':
        case 'Started':
            return {
                step: 4,
                isRejected: false,
                message: `You are offered this position at ${formattedDate}.`,
            };
        default:
            return;
    }
};

class ApplicationStatus extends Component {
    render() {
        const {width, theme} = this.props;
        const {application} = this.props;
        const {step: activeStep, isRejected, message} = getStep(application);
        const email = application.user.email;
        const stepperProps = width === 'xs' || width === 'sm' ? {orientation: 'vertical'} : {alternativeLabel: true};

        return (
            <Fragment>
                <Stepper activeStep={activeStep} {...stepperProps}>
                    {labels.map((label, step) => {
                        let props = {};
                        if (activeStep === step && isRejected) props.icon =
                            <RejectIcon style={{
                                color: theme.palette.error.main,
                                fontSize: 28,
                                margin: -2,
                                display: 'block'
                            }} />;
                        return (
                            <Step key={step}>
                                <StepLabel {...props}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Card>
                    <CardHeader
                        avatar={isRejected ? <RejectIcon style={{color: theme.palette.error.main, fontSize: 48}} /> :
                            <AcceptIcon color="secondary" style={{fontSize: 48}} />}
                        title={labels[activeStep]}
                        titleTypographyProps={{variant: 'h6',color:'textSecondary'}}
                        subheader={message}
                    />
                    {activeStep !== 0 && <CardActions>
                        <Button variant="outlined" color="primary" component="a" href={`mailto:${email}`}>
                            Email Recruiter
                        </Button>
                    </CardActions>}
                </Card>
            </Fragment>
        );
    }
}

export default withWidth()(withTheme(ApplicationStatus));
