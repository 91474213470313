import React, {Component} from 'react';
import MuiTextField from '@material-ui/core/TextField';

class TextField extends Component {
    inputRef = React.createRef();

    componentDidUpdate(prevProp, prevState, snapshot) {
        if(this.props.defaultValue !== prevProp.defaultValue) {
            this.inputRef.current.value = this.props.defaultValue;
        }
    }

    render() {
        return (
            <MuiTextField {...this.props} inputRef={this.inputRef}/>
        );
    }
}

export default TextField;