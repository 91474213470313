import React, {Component} from 'react';
import BasicDialog from './BasicDialog';
import FullscreenDialog from './FullscreenDialog';
import ResponsiveDialog from './ResponsiveDialog';

const Dialogs = {
    ['default']: BasicDialog,
    ['basic']: BasicDialog,
    ['fullscreen']: FullscreenDialog,
    ['responsive']: ResponsiveDialog,
    ['form']: ResponsiveDialog,
};

class DialogLayout extends Component {
    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
            mount: false,
            open: false,
        }
    }

    handleMountAndOpen = () => {
        this.setState({mount: true, open: true});
    };

    handleClose = () => {
        this.setState(state => ({...state, open: false}));
    };

    handleUnmount = () => {
        this.setState(state => ({...state, mount: false}));
    };

    handleConfirm = () => {
        const {
            variant = 'default',
            onConfirm: onConfirmProp
        } = this.props;
        let onConfirm = () => console.warn(
            'Dialog expects prop "onConfirm" to be a function or string name of ' +
            'its content\'s function; Or prop "variant" must be "form"'
        );
        if (onConfirmProp) {
            if (typeof onConfirmProp === 'string')
                onConfirm = this.childRef.current[onConfirmProp];
            else if (typeof onConfirmProp === 'function') {
                onConfirm = onConfirmProp;
            }
        }
        else {
            if (variant === 'form')
                onConfirm = this.childRef.current['handleSubmit'];
        }
        onConfirm();
    };

    render() {
        const {
            variant = 'default',
            onConfirm,
            controllerEvent = 'onClick',
            ...other
        } = this.props;
        const Dialog = Dialogs[variant];
        const children = React.Children.toArray(this.props.children);

        /* Controller */
        const controllerProps = {
            [controllerEvent]: this.handleMountAndOpen,
            disabled: children[0].props.disabled || this.state.mount,
        };
        if (event === 'onClick') controllerProps.disableRipple = true;
        const controllerComponent = React.cloneElement(children[0], controllerProps);

        /* Dialog */
        const dialogProps = {
            ...other,
            disableCloseOnAction: variant === 'form' || this.props.disableCloseOnAction,
            open: this.state.open,
            onClose: this.handleClose,
            onExited: this.handleUnmount,
            onAction: this.handleConfirm,
        };
        const dialogComponent = this.state.mount && (
            <Dialog {...dialogProps}>
                {!children[1] || typeof children[1] === 'string' ? children[1] : React.cloneElement(children[1], {
                    ref: this.childRef,
                    onClose: this.handleClose
                })}
            </Dialog>
        );

        return (
            <>
                {controllerComponent}
                {dialogComponent}
            </>
        );
    }
}

export default DialogLayout;