import React, {Component} from 'react';
import ApplicationForm from '../../../forms/ApplicationForm';
import PrivateButton from '../../../core/PrivateButton';
import DialogLayout from '../../../core/DialogLayout/index';

class ApplyButton extends Component {
    render() {
        const {isWatching, isApplied, onApply, talent} = this.props;
        return (
            <DialogLayout
                variant="form"
                title="Apply"
                actionButtonText="Apply"
            >
                <PrivateButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{maxWidth: 600, minWidth: 160, display: isWatching ? 'none' : ''}}
                    disabled={isApplied}
                >
                    {isApplied ? 'Applied' : 'Apply'}
                </PrivateButton>
                <ApplicationForm
                    onSubmit={onApply}
                    talent={talent}
                />
            </DialogLayout>
        );
    }
}

export default ApplyButton;