import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getKeywords, getSearchResult} from 'src/app/reducers/controller/searchReducer';
import toJS from 'src/app/components/hocs/toJS';
import * as ActionTypes from 'src/app/constants/actionTypes';

import SearchFilter from './SearchFilter';

class SearchFilterContainer extends Component {
    handleApplySearchFilter = (keywords) => {
        const {
            keywords: keywordsProp,
        } = this.props;

        this.props.dispatch({
            type: ActionTypes.SET_SEARCH_KEYWORDS,
            keywords: Object.assign(keywordsProp, keywords),
        });
    };

    render() {
        const {keywords, result} = this.props;
        return (
            <SearchFilter
                keywords={keywords}
                result={result}
                onApplySearchFilter={this.handleApplySearchFilter}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        keywords: getKeywords(state),
        result: getSearchResult(state),
    };
};

export default connect(mapStateToProps)(toJS(SearchFilterContainer));