import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import MuiAvatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/PermIdentity';

import {uploadAvatar, updateTalent} from '../../actions/talentActions';

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
        };
    }

    uploadAvatar = (file) => {
        if (!file) return;
        this.setState({uploading: true});
        this.props.dispatch(uploadAvatar(file))
            .then(({s3url}) => {
                this.props.dispatch(updateTalent({
                    id: this.props.id,
                    fullName: this.props.fullName,
                    photoUrl: s3url,
                }));
                return s3url;
            })
            .then(() => {
                this.setState({
                    uploading: false,
                });
            })
            .catch(() => {
                this.setState({
                    uploading: false
                });
            });
    };

    render() {
        const {src, classes} = this.props;
        return (
            <div className={classes.root}>
                <MuiAvatar src={src} className={classes.avatar}>
                    {!src && <PersonIcon className={classes.avatarIcon} />}
                </MuiAvatar>
                <div className={classes.middle}>
                    {this.state.uploading && (
                        <CircularProgress size={32} thickness={5.4} className={classes.circularProgress} />
                    )}
                </div>
                <div className={classNames(classes.middle, classes.hideChild)}>
                    <IconButton disabled={this.state.uploading}
                                component="label"
                                className={classes.iconButton}
                                disableRipple
                    >
                        {!this.state.uploading && <EditIcon className={classes.icon} />}
                        <input
                            type="file"
                            style={{display: 'none'}}
                            onChange={e => this.uploadAvatar(e.target.files[0])}
                        />
                    </IconButton>
                </div>
            </div>
        );
    }
}


const styles = (theme) => ({
    root: {
        position: 'relative',
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    avatarIcon: {
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    middle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        MsTransform: 'translate(-50%, -50%)',
        textAlign: 'center',
        borderRadius: '100%',
    },
    hideChild: {
        '&:hover > :nth-child(1)': {
            opacity: 1,
        },
    },
    iconButton: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        opacity: 0,
    },
    icon: {
        color: theme.palette.common.white,
    },
    circularProgress: {
        color: theme.palette.common.white,
        margin: 'auto',
        verticalAlign: 'middle',
    },
});

export default withStyles(styles)(connect()(Avatar));
