import React, {Component} from 'react';
import FormStateManager from '../stateManagers/FormStateManager';
import ResponsiveDialog from './ResponsiveDialog';

class FormDialog extends Component {
    render() {
        const {
            children,
            title,
            ...props
        } = this.props;
        return (
            <FormStateManager>
                {({formRef, handleDispatchSubmitEvent}) => (
                    <ResponsiveDialog
                        {...props}
                        title={title}
                        actionButtonText="Submit"
                        onAction={handleDispatchSubmitEvent}
                    >
                        {React.cloneElement(children, {formRef})}
                        {/*<FormComponent {...FormProps} formRef={formRef} />*/}
                    </ResponsiveDialog>
                )}
            </FormStateManager>
        );
    }
}

export default FormDialog;