import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import {createSelector} from 'reselect';
import {getJobs} from '../model/jobsReducer';
import getTenantId from '../misc/getTenantId';

const defaultState = Immutable.Map({
    keywords: Immutable.Map({
        title: '',
        city: '',
        province: '',
        country: '',
        minSalary: '',
        maxSalary: '',
        q: '',
        location: '',
        tenantId: getTenantId(),
    }),
    result: Immutable.Map({
        total: -1,
        ids: Immutable.List(),
    }),
    isFetching: false,
});

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case ActionTypes.SEARCH_SELECT_JOB:
            return state.set('selectedJobId', action.jobId);
        case ActionTypes.SEARCH_DESELECT_JOB:
            return state.delete('selectedJobId');

        case ActionTypes.SET_SEARCH_KEYWORDS:
            return state
                .set('keywords', Immutable.fromJS(action.keywords))
                .setIn(['result', 'total'], -1)
                .setIn(['result', 'ids'], Immutable.List());

        case ActionTypes.REQUEST_JOB_LIST:
            return state.set('isFetching', true);
        case ActionTypes.RECEIVE_JOB_LIST:
            return state.set('isFetching', false)
                .setIn(['result', 'total'], action.total)
                .updateIn(['result', 'ids'], (ids = Immutable.List()) => ids.concat(action.response.ids));

        case ActionTypes.FAILURE_JOB_LIST:
            return state.set('isFetching', false);

        default:
            return state;
    }
}

const _getFilteredJobIds = (state, filter) => {
    switch (filter) {
        case 'all':
            return state.controller.search.getIn(['result', 'ids']);
        case 'search':
            return state.controller.search.getIn(['result', 'ids']);
        case 'favorite':
            return state.controller.user.get('favoriteJobIds') || Immutable.List();
        case 'applied':
            return state.controller.user.get('appliedJobIds') || Immutable.List();
        default :
            return Immutable.List();
    }
};

export const getKeywords = (state) => state.controller.search.get('keywords');

export const getSearchedJobIds = (state) => state.controller.search.getIn(['result', 'ids']);

export const getSearchedJobCount = (state) => state.controller.search.getIn(['result', 'ids']).size;

export const getSearchResult = (state) => state.controller.search.get('result');

export const getTotal = (state) => state.controller.search.getIn(['result', 'total']);

export const getIsFetching = (state) => state.controller.search.get('isFetching');

export const getFirstJobId = createSelector(
    [_getFilteredJobIds],
    (ids) => ids.first() || -1
);

export const getFilteredJobs = createSelector(
    [_getFilteredJobIds, getJobs],
    (ids, jobs) => ids.map((id) => jobs.get(id.toString()))
);

export const getSearchedJobs = createSelector(
    [getSearchedJobIds, getJobs],
    (ids, jobs) => ids.map((id) => jobs.get(id.toString()))
);
