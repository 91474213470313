import React from 'react';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import {withStyles} from '@material-ui/core/styles';

const IconInput = ({Icon, classes, ...props}) => (
    <Paper elevation={0} className={classes.root}>
        {Icon && <Icon color="action" className={classes.icon} />}
        <Input {...props} disableUnderline fullWidth inputProps={{className: classes.input}} />
    </Paper>
);

const styles = (theme) => ({
    root: {
        height: theme.spacing(4.5),
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
    },
    icon: {
        marginLeft: 8,
        position: 'absolute',
        zIndex: 1,
    },
    input: {
        padding: '9px 0 8px 32px',
        '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px white inset',
            MozBoxShadow: '0 0 0 30px white inset',
        }
    }
});

export default withStyles(styles)(IconInput);
