import React, {PureComponent} from 'react';
import Typography from '@material-ui/core/Typography';
import HyperLink from 'src/app/components/core/HyperLink';
import DialogLayout from 'src/app/components/core/DialogLayout';
import AdvancedSearch from './AdvancedSearch';

class AdvancedSearchLink extends PureComponent {
    render() {
        return (
            <DialogLayout
                variant="responsive"
                title="Advanced Search"
                hideActionButton
                hideCancelButton
            >
                <HyperLink>
                    <Typography variant="body1" color="primary">Advanced Search</Typography>
                </HyperLink>
                <AdvancedSearch />
            </DialogLayout>
        );
    }
}

export default AdvancedSearchLink;