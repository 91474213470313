import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

export default function (state = Immutable.OrderedMap(), action = {}) {
    switch (action.type) {
        case ActionTypes.RECEIVE_TENANT_LIST:
            return state.mergeDeep(action.response.tenants);
        default:
            return state;
    }
}

export const getTenants = (state) => state.model.tenants;