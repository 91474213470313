import React, {Component} from 'react';
import withWidth from '@material-ui/core/withWidth/index';

class Responsive extends Component {
    render() {
        const {
            width,
            small,
            large,
        } = this.props;
        const condition = width === 'xs' || width === 'sm';
        return condition ? small : large;
    }
}

export default withWidth()(Responsive);