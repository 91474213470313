import React, {PureComponent} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from './layouts/Layout';

class Loader extends PureComponent {
    render() {
        return (
            <Layout fullsize center padding>
                <CircularProgress thickness={4.4} />
            </Layout>
        );
    }
}

export default Loader;