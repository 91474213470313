import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {setNewSearchKeywords} from 'src/app/actions/searchActions';
import LandingSearchBarForm from './LandingSearchBarForm';

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (keywords) => {
            dispatch(setNewSearchKeywords(keywords));
            dispatch(push(`/jobs/search`));
        },
    };
};

export default connect(null, mapDispatchToProps)(LandingSearchBarForm);
