import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import LocationIcon from '@material-ui/icons/LocationOn';
import IconInput from './IconInput';
import {getFormData} from 'src/app/utils/forms';

class SearchBarForm extends Component {
    searchRef = React.createRef();
    locationRef = React.createRef();

    componentDidUpdate(prevProp, prevState, snapshot) {
        if (prevProp.keywords !== this.props.keywords) {
            const {keywords} = this.props;
            if (this.searchRef.current) this.searchRef.current.value = keywords.q || '';
            if (this.locationRef.current) this.locationRef.current.value = keywords.location || '';
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const keywords = getFormData(e.target, [
            'q',
            'location',
        ]);
        if (typeof this.props.onSubmit === 'function') this.props.onSubmit(keywords);
    };

    render() {
        const {keywords} = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <IconInput
                            Icon={SearchIcon}
                            name="q"
                            placeholder="Job Title, Skills or Keywords"
                            defaultValue={keywords.q}
                            inputRef={this.searchRef}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <IconInput
                            Icon={LocationIcon}
                            name="location"
                            placeholder="Location"
                            defaultValue={keywords.location}
                            inputRef={this.locationRef}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button fullWidth variant="contained" color="primary" type="submit">
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default SearchBarForm;
