import React, {PureComponent} from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => {
    const paddings = [0, 8, 16, 24, 32, 40];
    const paddingStyles = {};
    paddings.forEach((padding) => {
        paddingStyles[`padding-${padding}`] = {
            padding: padding,
        };
    });

    return {
        root: {},
        ...paddingStyles,
    }
};

class Grids extends PureComponent {
    render() {
        const {padding, fluid = [], children, classes, ...props} = this.props;

        return (
            <div
                className={classNames({
                    [classes[`padding-${padding}`]]: padding
                })}
            >
                <Grid container {...props}>
                    {React.Children.map(children, (child, i) =>
                        <Grid item xs={12} {...fluid[i]}>
                            {child}
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Grids);