import React, {Component} from 'react';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

class SwipeableTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
    }

    handleChangeIndex = index => this.setState({index});

    handleClickTab = (_, index) => this.setState({index});

    render() {
        const {labels, children} = this.props;
        return (
            <>
                <Tabs
                    value={this.state.index}
                    onChange={this.handleClickTab}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {labels.map((label, index) => <Tab key={index} label={label} />)}
                </Tabs>
                <SwipeableViews
                    index={this.state.index}
                    onChangeIndex={this.handleChangeIndex}
                >
                    {children}
                </SwipeableViews>
            </>
        )
    }
}

export default SwipeableTabs;