/**
 * Created by chenghui on 5/24/17.
 */
import apnRequest from './request';

export * from './jobs';
export * from './files';

/* user */
export const getCurrentUser = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return apnRequest.send(`/account`, config);
};

export const createTalent = (talent) => {

    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(talent)
    };
    return apnRequest.send(`/talents/force_create`, config);
};

export const getMyTalent = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return apnRequest.send(`/my-talents?size=1`, config);
};

export const getTenantList = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return apnRequest.publicSend(`/tenants`, config);
};

//job
export const getJobList = (tenantId, query, filterStr) => {
    const sort = Object.keys(query).map(key => `&${key}=${query[key]}`).join('');
    const filter = filterStr ? '&search=' + filterStr : '';
    const queryStr = sort + filter;
    const config = {
        method: 'GET',
        headers: {}
    };

    return apnRequest.publicSend(`/jobs/tenant/${tenantId}?${queryStr}`, config);
};

export const getJobDetail = (jobId) => {
    const config = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        }
    };

    return apnRequest.publicSend(`/opendata/jobs/${jobId}`, config);
};

export const favoriteJob = (jobId) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([jobId])
    };

    return apnRequest.send(`/favorite/jobs`, config);
};

export const unfavoriteJob = (jobId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify([jobId])
    };

    return apnRequest.send(`/favorite/jobs`, config);
};

export const getFavoriteJobs = () => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return apnRequest.send(`/favorite/jobs`, config);
};

export const getAppliedJobs = (talentId) => {
    const config = {
        method: 'GET',
        headers: {}
    };
    return apnRequest.send(`/applications/talent/${talentId}`, config);
};

/* resume */
export const parseResume = (file) => {
    const requestBody = new FormData();
    requestBody.append('file', file);

    const config = {
        method: 'POST',
        headers: {},
        body: requestBody
    };

    return apnRequest.send(`/talents/parse-resume`, config);
};

export const createResume = (resume) => {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(resume)
    };

    return apnRequest.send(`/talent-resumes`, config);
};

export const deleteResume = (resumeId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return apnRequest.send(`/talent-resumes/${resumeId}`, config);
};

export const getResumesByTalentId = (talentId) => {

    const config = {
        method: 'GET',
        headers: {},
    };

    return apnRequest.send(`/talent-resumes/talent/${talentId}`, config);
};

export const createApplication = (application) => {//need to be changed
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(application)
    };

    return apnRequest.send(`/applications`, config);
};

export const updateApplication = (application) => {
    const config = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(application)
    };

    return apnRequest.send(`/applications/${application.id}`, config);
};

export const deleteApplication = (application) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
        },
        body: JSON.stringify(application)
    };

    return apnRequest.send(`/applications/${application.id}`, config);
};

export const updateTalent = (talent) => {
    const config = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(talent)
    };

    return apnRequest.send(`/talents/${talent.id}`, config);
};

export const createEducation = (education) => {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(education)
    };

    return apnRequest.send(`/talent-educations`, config);
};

export const updateEducation = (education) => {
    const config = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(education)
    };

    return apnRequest.send(`/talent-educations/${education.id}`, config);
};

export const deleteEducation = (educationId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return apnRequest.send(`/talent-educations/${educationId}`, config);
};

export const createExperience = (experience) => {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(experience)
    };

    return apnRequest.send(`/talent-experiences`, config);
};

export const updateExperience = (experience) => {
    const config = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(experience)
    };

    return apnRequest.send(`/talent-experiences/${experience.id}`, config);
};

export const deleteExperience = (experienceId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return apnRequest.send(`/talent-experiences/${experienceId}`, config);
};

export const createSkill = (skill) => {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(skill)
    };

    return apnRequest.send(`/talent-skills`, config);
};

export const updateSkill = (skill) => {
    const config = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(skill)
    };

    return apnRequest.send(`/talent-skills/${skill.id}`, config);
};

export const deleteSkill = (skillId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return apnRequest.send(`/talent-skills/${skillId}`, config);
};

export const createCertificate = (certificate) => {
    const config = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(certificate)
    };

    return apnRequest.send(`/talent-certificates`, config);
};

export const updateCertificate = (certificate) => {
    const config = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(certificate)
    };

    return apnRequest.send(`/talent-certificates/${certificate.id}`, config);
};

export const deleteCertificate = (certificateId) => {
    const config = {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    return apnRequest.send(`/talent-certificates/${certificateId}`, config);
};

export const createContact = (contact) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact)
    };
    return apnRequest.send(`/talent-contacts`, config);
};

export const updateContact = (contact) => {
    const config = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact)
    };
    return apnRequest.send(`/talent-contacts/${contact.id}`, config);
};

export const deleteContact = (contact) => {
    const config = {
        method: 'DELETE',
        headers: {}
    };
    return apnRequest.send(`/talent-contacts/${contact.id}`, config);
};
