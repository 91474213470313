import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: `underline ${theme.palette.primary.main}`,
        }
    }
});

class HyperLink extends Component {
    render() {
        const {onClick, children, classes, ...props} = this.props;
        return (
            <a onClick={onClick} className={classes.root} {...props}>{children}</a>
        );
    }
}

export default withStyles(styles)(HyperLink);