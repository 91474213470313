import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from '../reducers';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';


export const history = createBrowserHistory();

let composeEnhancer = compose;
if (process.env.NODE_ENV !== 'production') {
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
const store = createStore(
    createRootReducer(history),
    composeEnhancer(applyMiddleware(thunk, routerMiddleware(history)))
);

export default store;
