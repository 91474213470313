import * as ActionTypes from 'src/app/constants/actionTypes';
import {getKeywords} from 'src/app/reducers/controller/searchReducer';

export const setNewSearchKeywords = (keywords) => (dispatch, getState) => {
    const state = getState();
    const prevKeywords = getKeywords(state).toJS();
    Object.keys(prevKeywords).forEach(key => {
        keywords[key] = (key === 'country' || key === 'tenantId') ? prevKeywords[key] : (keywords[key] || '');
    });
    dispatch({
        type: ActionTypes.SET_SEARCH_KEYWORDS,
        keywords,
    });
};

export const updateSearchKeywords = (keywords) => (dispatch, getState) => {
    const state = getState();
    const prevKeywords = getKeywords(state).toJS();
    Object.keys(prevKeywords).forEach(key => {
        keywords[key] = keywords[key] || prevKeywords[key];
    });
    dispatch({
        type: ActionTypes.SET_SEARCH_KEYWORDS,
        keywords,
    });
};