import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import 'moment/locale/zh-cn.js';
// import { hot } from 'react-hot-loader/root'

import store, { history } from './store';
import Root from './components/Root';
import ThemeProvider from './theme/ThemeProvider';

moment.locale('en');

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <ThemeProvider>
                    <ConnectedRouter history={history}>
                        <Root />
                    </ConnectedRouter>
                </ThemeProvider>
            </Provider>
        )
    }
}

export default App;
