import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import DialogLayout from '../core/DialogLayout';
import EducationForm from '../forms/profile/EducationForm';

import {createEducation, updateEducation, deleteEducation} from '../../actions/talentActions';
import {getEducations} from '../../reducers/controller/userReducer';
import toJS from '../hocs/toJS';

class EducationsCard extends Component {
    handleCreateEducation = (education) => this.props.dispatch(createEducation(education));

    handleUpdateEducation = (education) => this.props.dispatch(updateEducation(education));

    handleDeleteEducation = (education) => this.props.dispatch(deleteEducation(education.id));

    render() {
        const {educations} = this.props;
        return (
            <Card>
                <CardHeader
                    title="Educations"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Create Education"
                            actionButtonText="Submit"
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <EducationForm
                                onSubmit={this.handleCreateEducation}
                            />
                        </DialogLayout>
                    }
                />
                {educations.map((education, index) => {
                    const startDate = education.startDate;
                    const endDate = education.endDate;
                    const start = startDate ? moment(startDate).format('L') + ' - ' : '';
                    const end = endDate ? moment(endDate).format('L') : 'Present';
                    const school = education.collegeName || '';
                    const degree = education.degreeName ? education.degreeName + ' of ' : '';
                    const major = education.majorName || '';
                    return (
                        <div key={index}>
                            <Divider />
                            <div className="flex spaceBetween">
                                <CardContent>
                                    <Typography variant='body2'>{school}</Typography>
                                    <Typography variant='body1'>{degree + major}</Typography>
                                    <Typography variant='caption'>{start + end}</Typography>
                                </CardContent>
                                <CardActions>
                                    <DialogLayout
                                        variant="form"
                                        title="Update Education"
                                        actionButtonText="Save"
                                    >
                                        <IconButton>
                                            <EditIcon />
                                        </IconButton>
                                        <EducationForm
                                            onSubmit={this.handleUpdateEducation}
                                            education={education}
                                        />
                                    </DialogLayout>
                                    <DialogLayout
                                        onConfirm={() => this.handleDeleteEducation(education)}
                                        actionButtonText="Delete"
                                        disableCloseOnAction={false}
                                    >
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                        Are you sure you want to delete this education?
                                    </DialogLayout>
                                </CardActions>
                            </div>
                        </div>
                    );
                })}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        educations: getEducations(state),
    };
}

export default connect(mapStateToProps)(toJS(EducationsCard));