import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getIsLoggedIn, getIsPreLoggedIn } from '../reducers/controller/appReducer';

class PublicRoute extends Component {
    render() {
        const { component: Component, isLoggedin, isPreLoggedIn, ...rest } = this.props;
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        console.log(from);
        return (
            <Route {...rest} render={props => (
                (isPreLoggedIn || isLoggedin) ? (
                    <Redirect to={from} />
                ) : (
                    <Component {...props} />
                )
            )} />
        )
    }
}

const mapStoreStateToProps = (state) => ({
    isLoggedin: getIsLoggedIn(state),
    isPreLoggedIn: getIsPreLoggedIn(state),

});

export default connect(mapStoreStateToProps)(PublicRoute);
