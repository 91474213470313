export const isEmail = (email) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
};

export const isPhone = (phone) => {
    const phoneRegex = /^([0-9]{10,})$/;
    return phoneRegex.test(phone);
};

export const isDate = (date) => {
    return Boolean(Date.parse(date));
};