import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const LandingLayout = ({ classes, children }) => (
    <div className={classes.root}>{children}</div>
);

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > :nth-child(1)': {
            marginBottom: theme.spacing(5),
        },
        '& > :nth-child(2)': {
            marginBottom: theme.spacing(25),
            padding: '0 24px',
            width: '960px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        }
    },
});

export default withStyles(styles)(LandingLayout);
