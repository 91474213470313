export default function() {
    let tenantId;
    const params = _getUrlParams();
    if(params.t) {
        const tDecoded = atob(params.t);
        if(tDecoded.match(/^[0-6]$/))
            document.cookie = `tenantId=${tDecoded};max-age=${60 * 60}`;
    }
    tenantId = _getCookie('tenantId');
    return tenantId || '0';
}

function _getUrlParams() {
    var params = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        params[key] = value;
    });
    return params;
}

function _getCookie(key) {
    let match = document.cookie.match(new RegExp(`${key}=([^;]*?);`));
    return match ? match[1] : undefined;
}