import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Menu from './Menu';
import If from 'src/app/components/core/If';

class NavigationUserKit extends Component {

    handleToLogin = () => {
        const { location, match, history } = this.props;
        if (match.url === '/login') {
            return;
        }
        if (match.url === '/signup') {
            return history.push('/login', location.state)
        }
        history.push('/login', Object.assign({}, location.state, { from: location.pathname }))
    };

    render() {
        return (
            <If loggedIn>
                <Menu />
                <Button color="inherit" onClick={this.handleToLogin}>Login</Button>
            </If>
        );
    }
}

export default withRouter(NavigationUserKit);
