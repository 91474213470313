import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

class TalentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const talent = getFormData(e.target, [
            'firstName',
            'lastName',
            'email',
            'phone',
            'company',
            'title',
            'country',
            'province',
            'city',
        ]);
        talent.id = this.props.talent.id;

        const errors = validateFormData(talent, {
            firstName: 'required',
            lastName: 'required',
            email: ['required', 'email'],
            phone: ['required', 'phone'],
        });

        this.setState({errors});
        if (Object.keys(errors).length) return;
        if (!isSubset(talent, this.props.talent)) this.props.onSubmit(talent);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {talent} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="firstName" label="First Name"
                                   defaultValue={talent.firstName}
                                   error={Boolean(this.state.errors['firstName'])}
                                   helperText={this.state.errors['firstName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="lastName" label="Last Name"
                                   defaultValue={talent.lastName}
                                   error={Boolean(this.state.errors['lastName'])}
                                   helperText={this.state.errors['lastName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="email" label="Email"
                                   defaultValue={talent.email}
                                   error={Boolean(this.state.errors['email'])}
                                   helperText={this.state.errors['email']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="phone" label="Phone"
                                   defaultValue={talent.phone}
                                   error={Boolean(this.state.errors['phone'])}
                                   helperText={this.state.errors['phone']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth name="company" label="Company"
                                   defaultValue={talent.company}
                                   error={Boolean(this.state.errors['company'])}
                                   helperText={this.state.errors['company']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth name="title" label="Title"
                                   defaultValue={talent.title}
                                   error={Boolean(this.state.errors['title'])}
                                   helperText={this.state.errors['title']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name="city" label="City"
                                   defaultValue={talent.city}
                                   error={Boolean(this.state.errors['city'])}
                                   helperText={this.state.errors['city']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name="province" label="State"
                                   defaultValue={talent.province}
                                   error={Boolean(this.state.errors['province'])}
                                   helperText={this.state.errors['province']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField fullWidth name="country" label="Country"
                                   defaultValue={talent.country}
                                   error={Boolean(this.state.errors['country'])}
                                   helperText={this.state.errors['country']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default TalentForm;
