import React, { PureComponent } from 'react';
import moment from 'moment/moment';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem/index';
import Typography from '@material-ui/core/Typography/index';
import { withStyles } from '@material-ui/core/styles/index';
import GridRow from '../core/layouts/GridRow';

const defaultFluid = [{ xs: 'auto' }, { xs: 'auto' }];

class JobListItem extends PureComponent {
    handleSelectJob = () => this.props.onSelectJob(this.props.job.id);

    render() {
        const { job, isSelected, onSelectJob, isApplied, classes, ...props } = this.props;
        return (
            <div
                className={classNames(
                    classes.listItem,
                    {
                        [classes.selected]: isSelected,
                    }
                )}
            >
                <ListItem button divider onClick={this.handleSelectJob} {...props} >
                    <div className={classes.root}>
                        <GridRow wrap="nowrap" fluid={defaultFluid}>
                            <Typography>
                                {job.title}
                            </Typography>
                            {isApplied && <div className={classes.appliedTag}>Applied</div>}
                        </GridRow>
                        <GridRow wrap="nowrap" fluid={defaultFluid}>
                            <Typography variant="caption" color='textSecondary' noWrap>
                                {`${job.company} @${job.city || 'None'}, ${job.province || 'None'}`}
                            </Typography>
                            <Typography variant="caption" color='textSecondary' noWrap align="right"
                                        className={classes.postingDate}>
                                {moment(job.postingDate, 'YYYYMMDD').fromNow()}
                            </Typography>
                        </GridRow>
                    </div>
                </ListItem>
            </div>
        );
    }
}

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    listItem: {
        transition: 'border .2s',
        WebkitTransition: 'border .2s', /* Safari */
        borderLeft: `0 solid ${theme.palette.primary.main}`,
        backgroundColor: '#f8f8f8',
    },
    selected: {
        borderLeft: `8px solid ${theme.palette.primary.main}`,
        backgroundColor: '#fff',
    },
    appliedTag: {
        color: 'white',
        fontSize: 14,
        backgroundColor: theme.palette.secondary.main,
        height: 20,
        padding: '0 8px',
        marginLeft: 8,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
    },
    postingDate: {
        flexShrink: 0,
    },
});

export default withStyles(styles)(JobListItem);
