import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
    Card,
    CardHeader,
    CardActions,
    Chip,
    IconButton,
    Divider,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import DialogLayout from '../core/DialogLayout';
import SkillForm from '../forms/profile/SkillForm';

import toJS from '../hocs/toJS';
import {getSkills} from '../../reducers/controller/userReducer';
import {createSkill, deleteSkill} from '../../actions/talentActions';

class SkillsCard extends Component {
    handleCreateSkill = (skill) => this.props.dispatch(createSkill(skill));

    handleDeleteSkill = (skill) => this.props.dispatch(deleteSkill(skill.id));

    render() {
        const {skills} = this.props;
        return (
            <Card>
                <CardHeader
                    title="Skills"
                    action={
                        <DialogLayout
                            variant="form"
                            title="Create Skill"
                            actionButtonText="Submit"
                        >
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <SkillForm
                                onSubmit={this.handleCreateSkill}
                            />
                        </DialogLayout>
                    }
                />
                {skills.length > 0 && (
                    <>
                        <Divider />
                        <CardActions style={{flexWrap: 'wrap'}} disableActionSpacing>
                            {skills.map((skill, index) => {
                                return (
                                    <div key={index} style={{padding: 8}}>

                                        <DialogLayout
                                            controllerEvent="onDelete"
                                            onConfirm={() => this.handleDeleteSkill(skill)}
                                            actionButtonText="Delete"
                                            disableCloseOnAction={false}
                                        >
                                            <Chip label={skill.skillName} />
                                            Are you sure you want to delete this skill?
                                        </DialogLayout>
                                    </div>
                                );
                            })}
                        </CardActions>
                    </>
                )}
            </Card>
        );
    }
}

function mapStateToProps(state) {
    const skills = getSkills(state);
    return {
        skills,
    };
}

export default connect(mapStateToProps)(toJS(SkillsCard));