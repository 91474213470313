import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

class EducationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const education = getFormData(e.target, [
            'collegeName',
            'degreeName',
            'majorName',
            'startDate',
            'endDate',
            {id: this.props.education && this.props.education.id}
        ]);

        const errors = validateFormData(education, {
            collegeName: 'required',
            degreeName: 'required',
            majorName: 'required',
            startDate: [
                'required',
                'date',
                'nonFutureDate',
                (education.endDate && !education.startDate) ? '*Required if end date is specified' : '',
            ],
            endDate: [
                'required',
                'date',
                (Date.parse(education.endDate) < Date.parse(education.startDate)) ? '*The end date cannot be before the start date' : '',
            ],
        });

        this.setState({errors});
        if (Object.keys(errors).length) return;
        if (!this.props.education || !isSubset(education, this.props.education)) this.props.onSubmit(education);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {education = {}} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField fullWidth required name="collegeName" label="School"
                                   defaultValue={education.collegeName}
                                   error={Boolean(this.state.errors['collegeName'])}
                                   helperText={this.state.errors['collegeName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="degreeName" label="Degree"
                                   defaultValue={education.degreeName}
                                   error={Boolean(this.state.errors['degreeName'])}
                                   helperText={this.state.errors['degreeName']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name='majorName' label="Major"
                                   defaultValue={education.majorName}
                                   error={Boolean(this.state.errors['majorName'])}
                                   helperText={this.state.errors['majorName']} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required type="date" name="startDate" label="Start Date"
                                   defaultValue={education.startDate}
                                   error={Boolean(this.state.errors['startDate'])}
                                   helperText={this.state.errors['startDate']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required type="date" name="endDate" label="End Date (or Expected End Date)"
                                   defaultValue={education.endDate}
                                   error={Boolean(this.state.errors['endDate'])}
                                   helperText={this.state.errors['endDate']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default EducationForm;
