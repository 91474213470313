import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '../core/TextField';
import {getFormData} from '../../utils/forms';
import {jobTypes as jobTypeFormOptions, postingDates as postingDateFormOptions} from '../../constants/formOptions';

class SearchFilterForm extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const keywords = getFormData(e.target, [
            'jobType',
            'postingDateFrom',
        ]);
        this.props.onSubmit(keywords);
    };

    render() {
        const {keywords} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3} wrap='nowrap'>
                    <Grid item>
                        <TextField
                            select
                            name="jobType"
                            defaultValue={keywords.jobType}
                            onChange={this.handleSubmit}
                            SelectProps={{native: true}}
                        >
                            {Object.values(jobTypeFormOptions).map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            name="postingDateFrom"
                            defaultValue={keywords.postingDateFrom}
                            onChange={this.handleSubmit}
                            SelectProps={{native: true}}
                        >
                            {Object.values(postingDateFormOptions).map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default SearchFilterForm;
