/**
 * Created by zitengli on 5/26/17.
 */

export const countryList = [
    //https://datahub.io/core/country-list
    {
        value: 'China -- CN',
        label: 'China'
    }, {
        value: 'United States -- US',
        label: 'United States'
    }
];

export const jobTypes = {
    '': {value: '', label: 'All Job Types'},
    'contract': {value: 'contract', label: 'Contract'},
    'full_time': {value: 'full_time', label: 'Full Time'},
    'part_time': {value: 'part_time', label: 'Part Time'},
    'direct_placement': {value: 'direct_placement', label: 'Direct Placement'},
    'right_to_hire': {value: 'right_to_hire', label: 'Right To Hire'},
};

export const postingDates = {
    '': {value: '', label: 'All Posting Dates'},
    'now-1d': {value: 'now-1d', label: 'In a Day'},
    'now-3d': {value: 'now-3d', label: 'In 3 Days'},
    'now-1w': {value: 'now-1w', label: 'In a Week'},
    'now-1M': {value: 'now-1M', label: 'In a Month'},
    'now-6M': {value: 'now-6M', label: 'In 6 Months'},
    'now-1y': {value: 'now-1y', label: 'In a Year'},
};

export const jobStatus = [
    {value: 'Open', label: 'OPEN'},
    {value: 'Reopened', label: 'REOPEN'},
    {value: 'OnHold', label: 'ON HOLD'},
    {value: 'Offer_Made', label: 'OFFER MADE'},
    {value: 'Cancelled', label: 'CANCELLED'},
    {value: 'Closed', label: 'CLOSED'},
    {value: 'Filled', label: 'FILLED'},
    {value: 'Expired', label: 'EXPIRED'},
    {value: 'Ignored', label: 'IGNORED'},
];


export const applicationStatus = [
    // {value: 'Watching', label: 'Watching'},
    {value: 'Applied', label: 'Submitted to AM'},
    {value: 'Called_Candidate', label: 'Called Candidate'},
    {value: 'Meet_Candidate_In_Person', label: 'Meet Candidate In Person'},
    {value: 'Internal_Rejected', label: 'Rejected by AM'},
    {value: 'Qualified', label: 'Qualified by AM'},
    {value: 'Submitted', label: 'Submitted to Client'},
    {value: 'Client_Rejected', label: 'Rejected by Client'},
    {value: 'Shortlisted_By_Client', label: 'Shortlisted by Client'},
    {value: 'Interview', label: 'Interview'},
    {value: 'Client_Rejected', label: 'Rejected by Client'},
    {value: 'Offered', label: 'Offered by Client'},
    {value: 'Offer_Rejected', label: 'Offer Declined'},
    {value: 'Offer_Accepted', label: 'Offer Accepted'},
    {value: 'Started', label: 'On boarded'},
];

export const CONTACT_TYPES = Object.freeze({
    PrimaryEmail: 'Primary_Email',
    Email: 'Email',
    PrimaryPhone: 'Primary_Phone',
    HomePhone: 'Home_Phone',
    WorkPhone: 'Work_Phone',
    CellPhone: 'Cell_Phone',
    Phone: 'Phone',
    Fax: 'Fax',
    Wechat: 'Wechat',
    LinkedIn: 'LinkedIn',
    Github: 'Github',
    Dice: 'Dice',
    LiePin: 'LiePin',
    Google_Plus: 'Google_Plus',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Weibo: 'Weibo'
});

export const contactTypes = {
    Primary_Email: {value: 'Primary_Email', label: 'Primary Email'},
    Email: {value: 'Email', label: 'Email'},
    Primary_Phone: {value: 'Primary_Phone', label: 'Primary Phone'},
    Home_Phone: {value: 'Home_Phone', label: 'Home Phone'},
    Work_Phone: {value: 'Work_Phone', label: 'Work Phone'},
    Cell_Phone: {value: 'Cell_Phone', label: 'Cell Phone'},
    Phone: {value: 'Phone', label: 'Phone'},
    Fax: {value: 'Fax', label: 'Fax'},
    Wechat: {value: 'Wechat', label: 'Wechat'},
    LinkedIn: {value: 'LinkedIn', label: 'LinkedIn'},
    Github: {value: 'Github', label: 'Github'},
    Dice: {value: 'Dice', label: 'Dice'},
    LiePin: {value: 'LiePin', label: 'LiePin'},
    Google_Plus: {value: 'Google_Plus', label: 'Google Plus'},
    Facebook: {value: 'Facebook', label: 'Facebook'},
    Twitter: {value: 'Twitter', label: 'Twitter'},
    Weibo: {value: 'Weibo', label: 'Weibo'},
};
