import React, {Component} from 'react';
import {connect} from 'react-redux';
import DeleteResumeButton from './DeleteResumeButton';
import {deleteResume} from '../../actions/talentActions';
import {getResumes} from '../../reducers/controller/userReducer';
import toJS from '../hocs/toJS';

import {
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
} from '@material-ui/core';

import UploadResumeButton from '../core/UploadResumeButton';
import Layout from '../core/layouts/Layout';
import Grids from '../core/layouts/Grids';

class Resume extends Component {
    render() {
        const {resumes, deleteResume} = this.props;
        return (
            <Layout fullsize horizontalCenter overflow>
                <Layout variant="page">
                    <Grids padding={8} spacing={1}>
                        <Card>
                            <CardHeader title="Resumes" />
                            {!!resumes.length && <Divider />}
                            <List style={{padding: '0px', backgroundColor: 'white'}}>
                                {resumes.map((resume, index, resumes) => {
                                    return (
                                        <ListItem key={resume.id}
                                                  button
                                                  divider={index !== resumes.length - 1}
                                                  onClick={() => window.open(resume.s3Link)}
                                        >
                                            <ListItemText>{resume.name}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <DeleteResumeButton onConfirm={() => deleteResume(resume.id)} />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Card>
                        <UploadResumeButton fullWidth variant='contained' color='primary' />
                    </Grids>
                </Layout>
            </Layout>
        );
    }
}

function mapStoreStateToProps(state) {
    const resumes = getResumes(state);
    return {
        resumes
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteResume: (id) => dispatch(deleteResume(id)),
    }
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(toJS(Resume));
