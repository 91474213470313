import * as ActionTypes from '../constants/actionTypes';
import * as apnSDK from '../../apn-sdk';
import {normalize, schema} from 'normalizr';

const tenant = new schema.Entity('tenants');

export const getTenantList = () => (dispatch) => {
    dispatch({
        type: ActionTypes.REQUEST_TENANT_LIST,
    });
    return apnSDK.getTenantList()
        .then(({response}) => {
            const normalizedData = normalize(response, [tenant]);
            dispatch({
                type: ActionTypes.RECEIVE_TENANT_LIST,
                response: {
                    ids: normalizedData.result,
                    tenants: normalizedData.entities.tenants,
                }
            });
            return {response}
        })
        .catch(err => {
            dispatch({
                type: ActionTypes.ADD_MESSAGE,
                message: {
                    type: 'error',
                    message: err.message || err
                }
            });
            throw err
        })
};