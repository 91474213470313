import React, {Component} from 'react';

import Paper from '@material-ui/core/Paper';
import NoJobSelected from '../JobDetail/molecules/NoResult';
import JobDetail from '../JobDetail';
import Layout from '../../../components/core/layouts/Layout';

class JobShareContainer extends Component {
/*    componentDidMount() {
        // window.location.href
        wx.config({
            debug: false,
            appId: 'wx3990c3ae4de0de6f',
            timestamp: 1544754087,
            nonceStr: 'Wm3WZYTPz0wzccnW',
            signature: 'f775526b36b6c63f50f8d4ac0a27cfe130f4528e',
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData'
            ]
        });

        wx.ready(function () {
            wx.updateAppMessageShareData({
                title: 'Reseller Business Partner',
                desc: `Description Overview: S.F. Express Corporation established its operations in the early 1990’s and pioneered door to door express deliveries across the China-HK border.  Today, SF’s coverage in Greater China is second to none within the express industry. This geographic landscape will be further enhanced by our commitment to continue investing and expanding in more lucrative international markets.`,
                link: 'http://apply.hitalentech.com/jobs/detail/1757',
                imgUrl: 'http://apply.hitalentech.com/assets/favicon.png',
                success: function () {
                    // 设置成功
                }
            });
        });
    }*/

    render() {
        const {match} = this.props;
        const jobId = match.params.jobId;
        if (jobId < 0) return <NoJobSelected />;
        return (
            <Layout fullsize horizontalCenter overflow>
                <Layout variant="page" component={Paper} square>
                    <JobDetail jobId={jobId} />
                </Layout>
            </Layout>
        );
    }
}

export default JobShareContainer;