import React, {Component} from 'react';
import {connect} from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import * as ActionTypes from '../constants/actionTypes';

const messageColors = {
    error: '#F4511E',
    warning: '#FFCC00',
    success: 'green',
    other: 'green',
};

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosing: false,
        };
    }

    onClose = (_, reason) => {
        if (reason === 'clickaway') return;
        /*this.props.dispatch({
            type: ActionTypes.CLOSE_MESSAGE
        });*/
        this.setState({isClosing: true});
    };

    onExited = () => {
        this.setState({isClosing: false});
        this.props.dispatch({
            type: ActionTypes.REMOVE_MESSAGE
        });
    };

    render() {
        const {message} = this.props;
        const current = message.get(0);
        if (!current) return null;
        return (
            <Snackbar
                anchorOrigin={{horizontal: 'center', vertical: 'top'}}
                open={Boolean(!this.state.isClosing && current)}
                autoHideDuration={2000}
                onClose={this.onClose}
                onExited={this.onExited}
            >
                <SnackbarContent
                    message={current.message}
                    style={{justifyContent: 'center', backgroundColor: messageColors[current.type]}}
                />
            </Snackbar>
        );
    }
}

const mapStateToProps = (state) => {
    const message = state.controller.message;
    return {
        message,
    }
};

export default connect(mapStateToProps)(Message);