import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    textDivider: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textDividerLine: {
        content: '',
        height: '1px',
        background: '#E0E0E0',
        flex: 1
    },
    textDividerText: {
        margin: '0 .25em'
    }
});

class TextDivider extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.textDivider}>
                <div className={classes.textDividerLine} />
                <div className={classes.textDividerText}>{this.props.children}</div>
                <div className={classes.textDividerLine} />
            </div>
        );
    }
}

export default withStyles(styles)(TextDivider);
