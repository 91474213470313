import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import {contactTypes} from '../../../constants/formOptions';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

const _getIdentifier = (str) => {
    let identifier = /\/in\/([^/]+)/.exec(str);
    if (identifier) {
        return identifier[1];
    }
};

const _getLiepinResId = (str) => {
    let map = {};
    const kvs = str.split('?')[1].split('&');
    kvs.forEach((value) => {
        const result = value.split('=');
        map[result[0]] = result[1];
    });
    return map['res_id_encode']
};

const _reformContact = (contact) => {
    switch (contact.type) {
        case 'Twitter':
        case 'Github':
        case 'Dice':
        case 'Google_Plus':
        case 'Facebook':
        case 'Weibo':
            contact.details = value;
            break;
        case 'LinkedIn':
            contact.contact = _getIdentifier(value);
            contact.details = value;
            break;
        case 'LiePin':
            contact.contact = _getLiepinResId(value);
            contact.details = value;
            break;
        case 'Wechat':
        default:
            break;
    }
};

class ContactForm extends Component {
    constructor(props) {
        super(props);
        const {contact = {}} = props;
        this.state = {
            type: contact.type || '',
            errors: {},
        };
        this.form = React.createRef();
    }

    handleTypeChange = (e) => this.setState({type: e.target.value});

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();
        const contact = getFormData(e.target, [
            'contact',
            {
                id: this.props.contact && this.props.contact.id,
                verified: false,
                type: this.state.type,
            }
        ]);
        const errors = validateFormData(contact, {
            type: 'required',
            contact: 'required',
        });
        this.setState({errors});
        if (Object.keys(errors).length) return;
        _reformContact(contact);
        if (!this.props.contact || !isSubset(contact, this.props.contact)) this.props.onSubmit(contact);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {contact = {}} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            required
                            label="Type"
                            fullWidth
                            value={this.state.type}
                            onChange={this.handleTypeChange}
                            error={Boolean(this.state.errors['type'])}
                            helperText={this.state.errors['type']}
                        >
                            {Object.keys(contactTypes).map(type => (
                                <MenuItem
                                    key={type}
                                    value={type}
                                >
                                    {contactTypes[type].label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name='contact' label="Contact"
                                   defaultValue={contact.contact}
                                   error={Boolean(this.state.errors['contact'])}
                                   helperText={this.state.errors['contact']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default ContactForm;
