import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

class ExperienceForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const experience = getFormData(e.target, [
            'title',
            'company',
            'startDate',
            'endDate',
            'description',
            {id: this.props.experience && this.props.experience.id}
        ]);

        const errors = validateFormData(experience, {
            title: 'required',
            company: 'required',
            startDate: [
                'date',
                'nonFutureDate',
                (experience.endDate && !experience.startDate) ? '*Required if end date is specified' : '',
            ],
            endDate: [
                'date',
                'nonFutureDate',
                (Date.parse(experience.endDate) < Date.parse(experience.startDate)) ? '*The end date cannot be before the start date' : '',
            ],
        });

        this.setState({errors});
        if (Object.keys(errors).length) return;
        if (!this.props.experience || !isSubset(experience, this.props.experience)) this.props.onSubmit(experience);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        const {experience = {}} = this.props;
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="title" label="Title"
                                   defaultValue={experience.title}
                                   error={Boolean(this.state.errors['title'])}
                                   helperText={this.state.errors['title']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth required name="company" label="Company"
                                   defaultValue={experience.company}
                                   error={Boolean(this.state.errors['company'])}
                                   helperText={this.state.errors['company']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth type="date" name="startDate" label="Start Date"
                                   defaultValue={experience.startDate}
                                   error={Boolean(this.state.errors['startDate'])}
                                   helperText={this.state.errors['startDate']}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth type="date" name="endDate" label="End Date"
                                   defaultValue={experience.endDate}
                                   error={Boolean(this.state.errors['endDate'])}
                                   helperText={this.state.errors['endDate']}
                        />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    //checked={this.state.checkedA}
                                    //onChange={this.handleChange('checkedA')}
                                    value="checkedA"
                                />
                            }
                            label="I currently work here"
                        />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <TextField fullWidth multiline rows="4" name="description" label="Description"
                                   defaultValue={experience.description}
                                   error={Boolean(this.state.errors['description'])}
                                   helperText={this.state.errors['description']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default ExperienceForm;
