import authRequest from './request';

export const uploadAvatar = (file) => {
    const requestBody = new FormData();
    requestBody.append('file', file);

    const config = {
        method: 'POST',
        headers: {},
        body: requestBody
    };

    return authRequest.send(`/files/upload-avatar`, config);
};