import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {logout} from 'src/app/actions/userActions';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import FavoriteIcon from '@material-ui/icons/FavoriteBorder';
import AppliedIcon from '@material-ui/icons/Archive';
import ProfileIcon from '@material-ui/icons/PermIdentity';
import ResumeIcon from '@material-ui/icons/Attachment';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import withWidth from '@material-ui/core/withWidth';
import {getUser} from 'src/app/reducers/controller/userReducer';
import toJS from 'src/app/components/hocs/toJS';

class Menu extends Component {
    anchorEl = null;

    constructor(props) {
        super(props);
        this.state = {
            openUserPanel: false
        }
    }

    handleOpenUserPanel = () => {
        this.setState({
            openUserPanel: true,
        });
    };

    handleCloseUserPanel = () => {
        this.setState({
            openUserPanel: false,
        });
    };

    handleLogout = () => {
        this.setState({
            openUserPanel: false,
        });
        this.props.dispatch(logout());
    };

    render() {
        const {user, width, /*talent,*/} = this.props;
        const {openUserPanel} = this.state;
        const userActions = [
            {
                Icon: FavoriteIcon,
                text: 'Favorite Jobs',
                component: Link,
                to: `/jobs/favorite`,
                onClick: this.handleCloseUserPanel
            },
            {
                Icon: AppliedIcon,
                text: 'Applied Jobs',
                component: Link,
                to: `/jobs/applied`,
                onClick: this.handleCloseUserPanel
            },
            {
                Icon: ProfileIcon,
                text: 'Profile',
                component: Link,
                to: `/profile`,
                onClick: this.handleCloseUserPanel
            },
            {
                Icon: ResumeIcon,
                text: 'Resume',
                component: Link,
                to: `/resume`,
                onClick: this.handleCloseUserPanel
            }
        ];

        const logout= [{
                Icon: LogoutIcon,
                text: 'Logout',
                component: 'button',
                //to: '/',
                onClick: this.handleLogout
            }];

        return (
            <Fragment>
                <Button
                    buttonRef={(button) => {
                        this.anchorEl = button
                    }}
                    color="inherit"
                    onClick={this.handleOpenUserPanel}
                    style={{minWidth:200}}
                >
                    <AccountCircleIcon />
                    {width === 'xs' ? null : user.email}
                </Button>
                <Popover
                    open={openUserPanel}
                    anchorEl={this.anchorEl}
                    onClose={this.handleCloseUserPanel}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                >
                    <List disablePadding style={{width:'200px'}}>
                        {userActions.map((userAction, index) => (
                            <ListItem key={index} color='inherit' button component={userAction.component}
                                      to={userAction.to} onClick={userAction.onClick}>
                                <ListItemIcon>
                                    <userAction.Icon />
                                </ListItemIcon>
                                <ListItemText primary={userAction.text} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List disablePadding>
                        {logout.map((userAction, index) => (
                            <ListItem key={index} color='inherit' button component={userAction.component}
                                      to={userAction.to} onClick={userAction.onClick}>
                                <ListItemIcon>
                                    <userAction.Icon />
                                </ListItemIcon>
                                <ListItemText primary={userAction.text} />
                            </ListItem>
                        ))}
                    </List>
                </Popover>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const user = getUser(state);
    return {
        user,
    }
};

export default withWidth()(connect(mapStateToProps)(toJS(Menu)));
