/* Check user agent */
export const isMobile = {
    Android: () => {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: () => {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: () => {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: () => {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: () => {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: () => {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};
/* Check if an object is subset of another object */
export const isSubset = (subObj, obj) => Object.keys(subObj).every((key) => (obj[key] && obj[key] === subObj[key]) || (!obj[key] && !subObj[key]));
/* Check if two paths are same. End '/'s are ignored */
export const isPathMatch = (path, path2) => path.replace(/\/$/, '') === path2.replace(/\/$/, '');