import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilteredJobs } from '../../../reducers/controller/searchReducer';
import { getAppliedJobIds } from '../../../reducers/controller/userReducer';
import toJS from '../../hocs/toJS';

import List from '@material-ui/core/List';

import JobListItem from '../JobListItem';

class JobsListStatic extends Component {
    render() {
        const {
            jobs,
            appliedJobIds,
            selectedJobId,
            onSelectJob: handleSelectJob,
        } = this.props;

        return (
            <List disablePadding>
                {jobs.map(job => {
                    return (
                        <JobListItem
                            key={job.id}
                            onSelectJob={handleSelectJob}
                            isSelected={job.id === selectedJobId}
                            isApplied={appliedJobIds.indexOf(job.id) !== -1}
                            job={job}
                        />
                    );
                })}
            </List>
        )
    }
}

function mapStateToProps(state, { jobsFilter }) {
    return {
        jobs: getFilteredJobs(state, jobsFilter),
        appliedJobIds: getAppliedJobIds(state),
    };
}

export default connect(mapStateToProps)(toJS(JobsListStatic));
