import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogLayout from 'src/app/components/core/DialogLayout';

class DeleteResumeButton extends Component {
    render() {
        const {onConfirm} = this.props;
        return (
            <DialogLayout
                title="Delete Resume?"
                actionButtonText="Delete"
                onConfirm={onConfirm}
            >
                <IconButton>
                    <DeleteIcon color="primary" />
                </IconButton>
            </DialogLayout>
        );
    }
}

export default DeleteResumeButton;