import React, {Component} from 'react';
import LandingLayout from './LandingLayout';
import LandingHero from './LandingHero';
import LandingSearchBar from './LandingSearchBar';
import LandingBackground from './LandingBackground';

class Landing extends Component {
    render() {
        return (
            <LandingLayout>
                <LandingHero />
                <LandingSearchBar />
                <LandingBackground />
            </LandingLayout>
        );
    }
}

export default Landing;
