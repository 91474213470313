import React, {Component} from 'react';
import {Route, Switch, Redirect,} from 'react-router-dom';

/* Global */
import Navigation from './Navigation';
import Message from './Message';
/* Landing */
import Landing from './Landing';
/* Account */
import Login from './account/Login';
import Signup from './account/Signup';
import ResetPassword from './account/ResetPassword';
import ThirdPartyAuth from './account/ThirdPartyAuth';
/* Job */
import Jobs from './Job';
/* Other */
import Profile from './Profile/index';
import Resume from './Resume';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Layout from './core/layouts/Layout';
import AppLoader from './core/AppLoader';
import IframeInterface from './IframeInterface';
import NotFound from './NotFound';

class Root extends Component {
    render() {
        return (
            <>
                <Message />
                <IframeInterface />
                <Layout variant="headerContent" fullscreen>
                    <Navigation />
                    <Switch>
                        <Route exact path="/" component={Landing} />
                        <Route path="/jobs" component={Jobs} />
                        <PrivateRoute exact path={`/profile`} component={Profile} />
                        <PrivateRoute exact path={`/resume`} component={Resume} />
                        <PublicRoute exact path={`/login`} component={Login} />
                        <PublicRoute exact path={`/signup`} component={Signup} />
                        <PublicRoute exact path={`/password`} component={ResetPassword} />
                        <Route path={`/auth/callback`} component={ThirdPartyAuth} />
                        <Route path="/nomatch" component={NotFound} />
                        <Route render={() => <Redirect to={{ pathname: '/nomatch' }} />} />
                    </Switch>
                </Layout>
                <AppLoader />
            </>
        )
    }
}

export default Root;
