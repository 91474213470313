import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFirstJobId } from 'src/app/reducers/controller/searchReducer';

import Paper from "@material-ui/core/Paper";

import Layout from "../../core/layouts/Layout";
import JobsHeader from "./JobsHeader";
import Responsive from "../../core/Responsive";
import JobsList from "./JobsListStatic";
import JobDetail from "../JobDetail";

class SavedJobContainer extends Component {
    state = {
        selectedJobId: -1,
    };

    handleSelectJob = (jobId) => this.setState({ selectedJobId: jobId });

    handleDeselectJob = () => this.setState({ selectedJobId: -1 });

    render() {
        const { firstJobId,jobsFilter } = this.props;
        const { selectedJobId } = this.state;

        return (
            <Layout variant="headerContent">
                <JobsHeader />
                <Responsive
                    small={
                        <Layout variant="sidebarContent" fullsize component={Paper} square>
                            <Layout overflow>
                                <JobsList
                                    selectedJobId={selectedJobId}
                                    onSelectJob={this.handleSelectJob}
                                    jobsFilter={jobsFilter}
                                />
                            </Layout>
                            <JobDetail
                                jobId={selectedJobId}
                                onClose={this.handleDeselectJob}
                            />
                        </Layout>
                    }
                    large={
                        <Layout variant="sidebarContent" fullsize component={Paper} square
                                style={{ overflow: 'hidden' }}>
                            <Layout overflow>
                                <JobsList
                                    selectedJobId={selectedJobId >= 0 ? selectedJobId : firstJobId}
                                    onSelectJob={this.handleSelectJob}
                                    jobsFilter={jobsFilter}
                                />
                            </Layout>
                            <Layout overflow>
                                <JobDetail jobId={selectedJobId >= 0 ? selectedJobId : firstJobId} />
                            </Layout>
                        </Layout>
                    }
                />
            </Layout>
        )
    }
}

function mapStateToProps(state, { match }) {
    const jobsFilter = match.params.jobsFilter;
    return {
        firstJobId: getFirstJobId(state, jobsFilter),
        jobsFilter
    };
}

export default connect(mapStateToProps)(SavedJobContainer);
