import * as ActionTypes from '../../constants/actionTypes';
import * as Immutable from 'immutable';
import {createSelector} from 'reselect';

export default function (state = Immutable.OrderedMap(), action = {}) {
    let newState;
    switch (action.type) {
        case ActionTypes.RECEIVE_JOB_LIST:
        case ActionTypes.RECEIVE_FILTERED_JOB_LIST:
            return state.mergeDeep(Immutable.fromJS(action.response.jobs));

        case ActionTypes.RECEIVE_FAVORITE_JOB_LIST:
        case ActionTypes.RECEIVE_APPLIED_JOB_LIST:
            newState = state.mergeDeep(Immutable.fromJS(action.jobs));
            return newState.equals(state) ? state : newState;

        case ActionTypes.RECEIVE_JOB:
            newState = state.update(action.job.id.toString(), (job = Immutable.Map()) => {
                return job.merge(Immutable.fromJS(action.job))});
            return newState.equals(state) ? state : newState;

        default:
            return state;
    }
}

/* Internal */
/*const getFilteredJobs = (state) => state.model.jobs;
const _getJobIds = (state, filter) => {
    switch (filter) {
        case 'all':
            return state.controller.jobsPage.get('ids');
        case 'search':
            return state.controller.jobsPage.get('ids');
        case 'favorite':
            return state.controller.currentUser.get('favoriteJobIds') || Immutable.List();
        case 'applied':
            return state.controller.currentUser.get('appliedJobIds') || Immutable.List();
        default :
            return Immutable.List();
    }
};*/

/* External */
export const getJob = (state, id) => state.model.jobs.get(id.toString()) || Immutable.Map();
export const getJobs = (state) => state.model.jobs;

/*export const getFirstJobId = createSelector(
    [_getJobIds],
    (ids) => ids.first() || -1
);*/

/*export const getFilteredJobs = createSelector(
    [_getJobIds, _getJobs],
    (ids, jobs) => ids.map((id) => jobs.get(id.toString()))
);*/
