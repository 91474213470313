import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';

import AdvancedSearchForm from './AdvancedSearchForm';
import toJS from 'src/app/components/hocs/toJS';
import {setNewSearchKeywords} from 'src/app/actions/searchActions';
import {getKeywords} from 'src/app/reducers/controller/searchReducer';
import {getTenants} from 'src/app/reducers/model/tenantsReducer';


const mapStateToProps = (state) => {
    return {
        keywords: getKeywords(state),
        tenants: getTenants(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (keywords) => {
            dispatch(setNewSearchKeywords(keywords));
            dispatch(push(`/jobs/search`));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AdvancedSearchForm));
