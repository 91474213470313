import React, {Component} from 'react';

import SelectedJobDetail from './SelectedJobDetail';
import SelectedJobDetailWithDialog from './SelectedJobDetailWithDialog';
import SearchFilter from './SearchFilter';
import JobList from './JobList/index';
import Responsive from '../../core/Responsive';
import {HeaderContent, SidebarContent, Overflow} from './elements';

import JobSearchContext from './JobSearchContext';

class JobSearch extends Component {
    render() {

        return (
            <JobSearchContext.Provider>
                <Responsive
                    small={
                        <HeaderContent>
                            <SearchFilter />
                            <SidebarContent>
                                <Overflow>
                                    <JobList />
                                </Overflow>
                                <SelectedJobDetailWithDialog />
                            </SidebarContent>
                        </HeaderContent>
                    }
                    large={
                        <HeaderContent>
                            <SearchFilter />
                            <SidebarContent>
                                <Overflow>
                                    <JobList selectFirstJobOnLoad />
                                </Overflow>
                                <Overflow>
                                    <SelectedJobDetail />
                                </Overflow>
                            </SidebarContent>
                        </HeaderContent>
                    }
                />
            </JobSearchContext.Provider>
        );
    }
}

export default JobSearch;
