import * as ActionTypes from '../../constants/actionTypes';
import Immutable from 'immutable';

export default function (state = Immutable.OrderedMap(), action = {}) {
    switch (action.type) {
        case ActionTypes.RECEIVE_USER:
            return state.set(action.user.id.toString(), Immutable.fromJS(action.user));

        case ActionTypes.LOGOUT:
            return state.clear();
        default:
            return state;
    }
}