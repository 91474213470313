import React from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';

export const HeaderContent = withStyles(
    () => ({
        root: {
            display: 'flex',
            overflow:'hidden',
            flexDirection: 'column',
            '& > :nth-child(1)': {
                flex: '0 0 auto',
            },
            '& > :nth-child(2)': {
                flex: '1 1 0',
                overflow:'hidden',
            }
        },
    })
)(
    ({classes, ...other}) => (
        <div className={classes.root} {...other} />
    )
);

export const SidebarContent = withStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexBasis: 0,
            height: '100%',
            '& > :nth-child(1)': {
                width: theme.spacing(50),
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                }
            },
            '& > :nth-child(2)': {
                borderLeft: `1px solid ${theme.palette.divider}`,
                width: `calc(100% - ${theme.spacing(50)}px)`,
                [theme.breakpoints.down('sm')]: {
                    width: 0,
                }
            }
        },
    })
)(
    ({classes, ...other}) => (
        <Paper className={classes.root} square {...other} />
    )
);

export const Overflow = withStyles(
    () => ({
        root: {
            overflowX: 'hidden',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
        },
    })
)(
    ({classes, ...other}) => (
        <div className={classes.root} {...other} />
    )
);
