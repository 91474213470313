import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import JobSearch from "./JobSearch";
import PrivateRoute from "../PrivateRoute";
import MyJobs from "./MyJobs";
import JobShare from "./JobShare";


const Jobs = ({ location, match, }) => {
    return (
        <React.Fragment key={location.key}>
            <Switch>
                <Route exact path={match.url} render={() => <Redirect to={{ pathname: '/jobs/search' }} />} />
                <Route exact path={`${match.url}/search`} component={JobSearch} />
                <Route exact path={`${match.url}/detail/:jobId`} component={JobShare} />
                <PrivateRoute exact path={`${match.url}/:jobsFilter`} component={MyJobs} />
            </Switch>
        </React.Fragment>
    )
};
export default Jobs;
