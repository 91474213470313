import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import model from './model';
import controller from './controller';

export default (history) => combineReducers({
    router: connectRouter(history), // router's key MUST be "router", do not change
    model,
    controller
});
