import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const getPadding = (padding) => {
    const paddingArray = padding.split(' ').map(str => parseInt(str));
    switch (paddingArray.length) {
        case 1:
            return {
                paddingTop: paddingArray[0],
                paddingRight: paddingArray[0],
                paddingBottom: paddingArray[0],
                paddingLeft: paddingArray[0],
            };
    }
};

class Layout extends PureComponent {
    render() {
        const {
            variant,
            fullscreen,
            fullsize,
            overflow,
            center,
            horizontalCenter,
            padding,
            flex,
            classes,
            className: classNameProp,
            component: Component = 'div',
            ...other
        } = this.props;
        const className = classNames(
            classes.root,
            {
                [classes[variant]]: !!variant,
                [classes.fullsize]: fullsize,
                [classes.fullscreen]: fullscreen,
                [classes.overflow]: overflow,
                [classes.center]: center,
                [classes.horizontalCenter]: horizontalCenter,
                [classes.padding]: padding,
                [classes.flex]: flex,
            },
            classNameProp
        );
        return (
            <Component className={className} {...other} />
        );
    }
}

const styles = (theme) => ({
    root: {},
    fullsize: {
        height: '100%',
        width: '100%',
    },
    fullscreen: {
        height: '100vh',
        width: '100vw',
    },
    overflow: {
        overflowX: 'hidden',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    horizontalCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    /* variants */
    headerContent: {
        display: 'flex',
        flexDirection: 'column',
        '& > :nth-child(1)': {
            flex: '0 0 auto',
        },
        '& > :nth-child(2)': {
            flex: '1 1 0',
        }
    },
    sidebarContent: {
        display: 'flex',
        flexBasis: 0,
        height: '100%',
        '& > :nth-child(1)': {
            width: theme.spacing(50),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            }
        },
        '& > :nth-child(2)': {
            borderLeft: `1px solid ${theme.palette.divider}`,
            width: `calc(100% - ${theme.spacing(50)}px)`,
            [theme.breakpoints.down('sm')]: {
                width: 0,
            }
        }
    },
    page: {
        height: 'auto',
        width: theme.spacing(120),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    padding: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    flex: {
        display: 'flex',
    }
});

export default withStyles(styles)(Layout);
