import {createSelector} from "reselect";

export const getPathname = (state) => state.router.location.pathname;

const getParamString = (state) => state.router.location.search;

export const getParams = createSelector(
    [getParamString],
    (paramString) => {
        if(!paramString)
            return null;
        else {
            paramString = paramString.substring(1);
            return JSON.parse('{"' + decodeURI(paramString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
        }
    }
);
