import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import DialogLayout from '../../../core/DialogLayout/index';

class CancelApplicationButton extends Component {
    render() {
        const {isWatching, onCancelApplication} = this.props;
        return (
            <DialogLayout
                title="Cancel Application?"
                cancelButtonText="Close"
                actionButtonText="Cancel Application"
                onConfirm={onCancelApplication}
            >
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    style={{maxWidth: 600, minWidth: 160, display: isWatching ? '' : 'none'}}
                >
                    Cancel Application
                </Button>
            </DialogLayout>
        );
    }
}

export default CancelApplicationButton;