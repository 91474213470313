import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getParams} from 'src/app/reducers/routing';
import * as ActionTypes from '../constants/actionTypes';

class IframeInterface extends Component {
    componentDidMount() {
        const {params} = this.props;
        if(params) {
            this.props.dispatch({
                type: ActionTypes.CONFIG_IFRAME_PARAMS,
                params
            })
        }
    }

    render() {
        return (
            <div />
        );
    }
}

const mapStateToProps = (state) => ({
    params: getParams(state),
});

export default connect(mapStateToProps)(IframeInterface);