import React, {Component} from 'react';


import BasicInfoCard from './BasicInfoCard';
import ExperiencesCard from './ExperiencesCard';
import EducationsCard from './EducationsCard';
import SkillsCard from './SkillsCard';
import CertificatesCard from './CertificatesCard';
import ContactsCard from './ContactsCard';

import Layout from '../core/layouts/Layout';
import Grids from '../core/layouts/Grids';

class Profile extends Component {
    render() {
        return (
            <Layout fullsize horizontalCenter overflow>
                <Layout variant="page">
                    <Grids padding={8} spacing={1}>
                        <BasicInfoCard />
                        <ExperiencesCard />
                        <EducationsCard />
                        <SkillsCard />
                        <CertificatesCard />
                        <ContactsCard />
                    </Grids>
                </Layout>
            </Layout>
        );
    }
}

export default Profile;
