import React, {Component} from 'react';
import {connect} from 'react-redux';
import {replace} from 'connected-react-router'

import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as ActionTypes from '../../constants/actionTypes'
import {loginBySocialAccount} from '../../actions/userActions'

import Back from '@material-ui/icons/ChevronLeft';

const styles = {
    topPanel: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between'
    },
    textField: {
        height: '100%'
    },
    textFieldContent: {
        width: '100%',
        top: '0px',
        bottom: '0px',
        lineHeight: '2.5em',
    }
};

class ThirdPartyAuth extends Component {

    componentDidMount() {
        // this.getAuthCode();
        this.handleResult();
    }

    goBack = () => {
        this.props.history.goBack();
    };

    handleResult = () => {
        const {dispatch} = this.props;
        let result = {};
        location.search.replace('?', '').split('&').forEach(value => {
            let kv = value.split('=');
            let key = kv[0];
            let val = kv[1];
            result[key] = val;
        });

        if (!result.code) {
            dispatch(replace('/'));
        }
        else {
            const code = result.code;
            const state = result.state;

            const authType = state.split('_')[0];
            let provider = '';
            let redirect_uri = '';
            switch (authType) {
                case 'linkedin':
                    //send linkedin code to server
                    provider = 'LINKEDIN';
                    redirect_uri = `${window.location.origin}/auth/callback`;
                    break;
                case 'wechat':
                    //send wechat code to server
                    provider = 'WECHAT';
                    break;
            }
            dispatch(loginBySocialAccount(code, provider, redirect_uri))
                .then(response => {
                    dispatch({
                        type: ActionTypes.ADD_MESSAGE,
                        message: {
                            message: 'Login success',
                            type: 'hint'
                        }
                    });
                })
                .catch(e => {
                    dispatch({
                        type: ActionTypes.ADD_MESSAGE,
                        message: {
                            message: e.message || e,
                            type: 'error'
                        }
                    });
                    throw e;
                })
                .finally(() => {
                    dispatch(replace('/'));
                })
        }
    };


    render() {
        return (
            <div className="contentContainer">
                <div style={styles.topPanel}>
                    <IconButton
                        nativeColor='white'
                        onClick={this.goBack}
                        style={{display: 'inline-flex'}}
                    >
                        <Back />
                    </IconButton>
                    <div style={{
                        margin: 'auto 3em auto auto',
                        display: 'inline-flex',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <span style={{margin: 'auto', color: 'white'}}>Authenticating...</span>
                    </div>
                </div>
                <div style={{display: 'flex', margin: 'auto', marginTop: '50%'}}>
                    <CircularProgress size={40}
                                      style={{margin: 'auto'}} />
                </div>
            </div>
        )
    }
}

export default connect()(ThirdPartyAuth);
