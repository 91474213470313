/**
 * Created by Jiayuan Deng on 7/6/18.
 */
//app status
export const CHECKED_LOGIN_TOKEN = 'checked login token';
export const LOADED_USER_DATA = 'user data loaded';
export const LOGIN = 'logged in';
export const LOGOUT = 'logged out';

//user
export const RECEIVE_USER = 'receive user';

//tenants
export const REQUEST_TENANT_LIST = 'request_tenant_list';
export const RECEIVE_TENANT_LIST = 'receive_tenant_list';
//export const FAILURE_TENANT_LIST = 'failure_tenant_list';

//jobs and pagination
export const REQUEST_JOB = 'request_job';
export const RECEIVE_JOB = 'receive_job';
//export const FAILURE_JOB = 'failure_job';
//export const RECEIVE_PAGINATION_INFO = 'receive_pagination_info';
//export const CLEAR_JOB_LIST = 'clear_job_list';

//search
export const SEARCH_SELECT_JOB = 'jobs/search: select job';
export const SEARCH_DESELECT_JOB = 'jobs/search: deselect job';
export const SET_SEARCH_KEYWORDS = 'set search keywords';
export const REQUEST_JOB_LIST = 'request_job_list';
export const RECEIVE_JOB_LIST = 'receive_job_list';
export const FAILURE_JOB_LIST = 'failure_job_list';

//favorite jobs
export const REQUEST_FAVORITE_JOB_LIST = 'request_favorite_jobs';
export const RECEIVE_FAVORITE_JOB_LIST = 'receive_favorite_jobs';
export const ADD_FAVORITE_JOBS = 'add_favorite_jobs';
export const DELETE_FAVORITE_JOBS = 'delete_favorite_jobs';
export const REQUEST_APPLIED_JOB_LIST = 'request_applied_jobs';
export const RECEIVE_APPLIED_JOB_LIST = 'receive_applied_jobs';

/*
//location
export const REQUEST_CITES = 'request_cities';
export const RECEIVE_CITES = 'receive_cities';

//companies
export const REQUEST_COMPANIES = 'request_companies';
export const RECEIVE_COMPANIES = 'receive_companies';
*/

//talent
export const CREATE_TALENT = 'create talent';
export const RECEIVE_TALENT = 'receive talent';

//contact
export const CREATE_TALENT_CONTACT = 'create_talent_contact';
export const UPDATE_TALENT_CONTACT = 'update_talent_contact';
export const DELETE_TALENT_CONTACT = 'delete_talent_contact';

//application
export const RECEIVE_APPLICATION = 'receive_application';
export const DELETE_APPLICATION = 'delete_application';

//resume
export const RECEIVE_RESUME_RESULT = 'receive_resume_result';
export const RECEIVE_RESUME_INFO = 'receive_resume_info';
export const DELETE_RESUME_INFO = 'delete_resume_info';
export const RECEIVE_RESUMES = 'receive resumes';

//errors
export const ADD_MESSAGE = 'add_message';
export const CLOSE_MESSAGE = 'close_message';
export const REMOVE_MESSAGE = 'remove_message';

//experiences
export const ADD_TALENT_EXPERIENCE = 'add_TALENT_EXPERIENCE';
export const UPDATE_TALENT_EXPERIENCE = 'udpate_TALENT_EXPERIENCE';
export const DELETE_TALENT_EXPERIENCE = 'delete_TALENT_EXPERIENCE';

//educations
export const ADD_TALENT_EDUCATION = 'add_TALENT_EDUCATION';
export const UPDATE_TALENT_EDUCATION = 'udpate_TALENT_EDUCATION';
export const DELETE_TALENT_EDUCATION = 'delete_TALENT_EDUCATION';

//certificates
export const ADD_TALENT_CERTIFICATE = 'add_TALENT_CERTIFICATE';
export const UPDATE_TALENT_CERTIFICATE = 'udpate_TALENT_CERTIFICATE';
export const DELETE_TALENT_CERTIFICATE = 'delete_TALENT_CERTIFICATE';

//skills
export const ADD_TALENT_SKILL = 'add_TALENT_SKILL';
export const DELETE_TALENT_SKILL = 'delete_TALENT_SKILL';


//search keywords
export const RECEIVE_FILTERED_JOB_LIST = 'receive_filtered_job_list';

//iframe
export const CONFIG_IFRAME_PARAMS = 'config_iframe_params';
