import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import toJS from 'src/app/components/hocs/toJS';
import SearchBarForm from './SearchBarForm';
import {setNewSearchKeywords} from 'src/app/actions/searchActions';
import {getKeywords} from 'src/app/reducers/controller/searchReducer';
import {getPathname} from 'src/app/reducers/routing';
import {isPathMatch} from 'src/app/utils';

const mapStateToProps = (state) => {
    const keywords = isPathMatch(getPathname(state), '/jobs/search') ? getKeywords(state) : {};
    return {
        keywords,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (keywords) => {
            dispatch(setNewSearchKeywords(keywords));
            dispatch(push(`/jobs/search`));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SearchBarForm));
