import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {getFormData, validateFormData} from '../../../utils/forms';
import {isSubset} from '../../../utils/general';

class CertificateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
        this.form = React.createRef();
    }

    handleSubmit = () => {
        this.form.current.dispatchEvent(new Event('submit'));
    };

    _handleSubmit = (e) => {
        e.preventDefault();

        const skill = getFormData(e.target, [
            'skillName',
        ]);

        const errors = validateFormData(skill, {
            skillName: 'required',
        });

        this.setState({errors});
        if (Object.keys(errors).length) return;
        this.props.onSubmit(skill);
        if (typeof this.props.onClose === 'function') this.props.onClose();
    };

    render() {
        return (
            <form onSubmit={this._handleSubmit} ref={this.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField fullWidth required name="skillName" label="Skill Name"
                                   error={Boolean(this.state.errors['skillName'])}
                                   helperText={this.state.errors['skillName']}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export default CertificateForm;
