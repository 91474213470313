/**
 * Created by chenghui on 6/23/17.
 */

// const _devHost = 'https://api.hitalent.us';
const _devHost = "http://api.hitalentech.com";

const apn = {
    host: process.env.REACT_APP_API_HOST || _devHost
};

const apiPrefix = '/api/v1';

const apnRequest = {
    apn: apn,

    send(endPoint, config) {
        const url = apn.host + apiPrefix + endPoint;
        let token = localStorage.token;
        if (token) {
            token = JSON.parse(token);
            config.headers['Authorization'] = `Bearer ${token.access_token}`;
            /* this.refreshing is a promise */
            return (this.refreshing || Promise.resolve(''))
                .then(() => fetch(url, config))
                .catch(() => _retry(url, config, token.refresh_token))
                .then(_handleResponseToJson)
                .catch((err) => {
                    if (err.error === 'invalid_token') {
                        return _retry(url, config, token.refresh_token)
                            .then(_handleResponseToJson)
                    }
                    throw err
                })
        }
        else {
            return Promise.reject('You have logged out!')
        }
    },

    publicSend(endPoint, config) {
        const url = apn.host + apiPrefix + endPoint;
        return fetch(url, config)
            .then(_handleResponseToJson)
    },

    refreshToken(refresh_token) {
        if (!refresh_token) return Promise.resolve(false);
        const url = `${apn.host + apiPrefix}/refresh-token`;
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({refresh_token})
        };
        this.refreshing = fetch(url, config)
            .then(_handleResponseToJson)
            .then(({response}) => {
                    response.createdAt = Date.now();
                    localStorage.setItem('token', JSON.stringify(response));

                    return true;
                }
            )
            .catch((err) => {
                localStorage.removeItem('token');
                return false;
            });
        return this.refreshing
    },
    login(credentials) {
        const url = `${apn.host + apiPrefix}/login`;
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(credentials)
        };

        return fetch(url, config)
            .then(_handleResponseToJson)
            .then(({response}) => {
                response.credential['createdAt'] = Date.now();
                localStorage.setItem('token', JSON.stringify(response.credential));

                return true;
            });
    },
    loginWithOauth2Code(code, provider, redirect_uri) {
        const url = `${apn.host + apiPrefix}/login/social`;
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify({
                code: code,
                provider: provider,
                redirect_uri

            })
        };
        return fetch(url, config)
            .then(_handleResponseToJson)
            .then(({response}) => {
                    response.credential['createdAt'] = Date.now();
                    localStorage.setItem('token', JSON.stringify(response.credential));

                    return response;
                }
            );
    },
    logout() {
        return Promise.resolve(true)
            .then(success => {
                if (success) {
                    localStorage.removeItem('token');
                }
                return success
            });
    },
    checkLoginToken() {
        let token = localStorage.token ? JSON.parse(localStorage.token) : null;
        if (token) {
            if (7000000 + token.createdAt > Date.now()) {
                console.log(token);
                return Promise.resolve(true)
            }
            else {
                return this.refreshToken(token.refresh_token);
            }
        }

        return Promise.resolve(false);
    },

    register(credentials) {
        const url = `${apn.host + apiPrefix}/consumer/register`;
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify(credentials)
        };

        return fetch(url, config)
            .then(_handleResponseToJson)
            .then(({response}) => {
                    response.credential['createdAt'] = Date.now();
                    localStorage.setItem('token', JSON.stringify(response.credential));
                    return true;
                }
            );
    },

    resetPassword(email) {
        const url = `${apn.host + apiPrefix}/account/reset_password/init`;
        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            cache: 'no-cache',
            body: JSON.stringify({email})
        };

        return fetch(url, config)
            .then(_handleResponseToJson);
    }
};

export default apnRequest;

const _handleResponseToJson = (response) => {

    if (!response.ok) {

        if (response.status === 401) {
            return response.json()
                .then(data => {
                    data.status = 401;
                    throw data
                })
        }
        if (response.status === 403) {
            //global error
            throw response.status;
        }
        if (response.status === 404) {
            //global error
            throw response.status;
        }
        if (response.status === 400) {
            return response.json()
                .then(data => {
                    data.status = 400;
                    throw data
                })
        }
        if (response.status === 409) {
            return response.json()
                .then(data => {
                    data.status = 409;
                    throw data
                })
        }
        if (response.status >= 500) {
            throw response.statusText;
        }
    }

    if (response.status === 204) {
        return 'OK'
    }

    // return response.text().then((text) => text ? JSON.parse(text) : {});
    const totalCount = response.headers.get('Pagination-Count');
    const pageSize = response.headers.get('Pagination-Limit');
    const currentPage = response.headers.get('Pagination-Page');

    return response.json()
        .then(json => ({
            response: json,
            pagination: {
                totalCount,
                pageSize,
                currentPage
            }
        }))
        .catch(err => ({}));
};

const _retry = (url, config, refresh_token) => {
    return (apnRequest.refreshing || apnRequest.refreshToken(refresh_token))
        .then((success) => {
            if (success) {
                let token = JSON.parse(localStorage.token);
                config.headers['Authorization'] = `Bearer ${token.access_token}`;
                return fetch(url, config)
            }
            else {
                // store.dispatch({
                //     type: 'logout'
                // })
            }
        })
};
