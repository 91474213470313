import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from './core/Loader';
import { getIsLoggedIn, getIsLoggedOut, getIsPreLoggedIn } from '../reducers/controller/appReducer';

import {
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom';

const PrivateRoute = ({ component: Component, isLoggedin, isLoggedOut, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            isLoggedin ? (
                <Component {...props} />
            ) : isLoggedOut ? (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
            ) : (
                <Loader />
            )
        )} />
    )
};

function mapStoreStateToProps(state) {
    return {
        isLoggedin: getIsLoggedIn(state),
        isLoggedOut: getIsLoggedOut(state),
    };
}

export default withRouter(connect(mapStoreStateToProps)(PrivateRoute));
