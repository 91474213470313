import apnRequest from './request';

export const getJobListES = (from = 0, size = 1000, sort = [], query = {}) => {

    const config = {
        method: 'POST',
        headers: {},
        body: JSON.stringify({from, size, sort, query})
    };

    return apnRequest.publicSend(`/search-public-jobs`, config);
};