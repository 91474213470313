import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import NavigationLayout from './NavigationLayout';
import Logo from './Logo';
import NavigationSearchKit from './SearchKit';
import UserKit from './UserKit';

class Navigation extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/">
                    <NavigationLayout transparent>
                        <Logo />
                        <UserKit />
                    </NavigationLayout>
                </Route>
                <Route path="*">
                    <NavigationLayout>
                        <Logo />
                        <NavigationSearchKit />
                        <UserKit />
                    </NavigationLayout>
                </Route>
            </Switch>
        )
    }
}

export default Navigation;