import React, {PureComponent} from 'react';
import Typography from '@material-ui/core/Typography';

class JobMetadata extends PureComponent {
    render() {
        const {company, city = 'None', province = 'None', postingDate, id} = this.props;
        return (
            <>
                <Typography variant="caption" color="textPrimary" display='block'>
                    {/*`${company} @${city}, ${province}`*/
                        `${city}, ${province}`}
                </Typography>
                <Typography variant="caption" color={'textSecondary'}>
                    {`Posted ${postingDate} • ID: ${id}`}
                </Typography>
            </>
        );
    }
}

export default JobMetadata;
